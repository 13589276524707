import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { RecordRTCPromisesHandler } from "recordrtc";
import styled from "styled-components";
import { v4 } from "uuid";
import {
  useCreateAsrTtsAdminAnnotationMutation,
  useMeQuery,
  useSignedUploadToGcpMutation,
} from "../../../../generated/graphql";

type TextToSpeechAugmentationProps = {};
type RecordingState = {
  recording: boolean;
};
const Container = styled.div`
  display: grid;
  grid-template-rows: 250px auto 400px;
  grid-template-columns: 250px 900px 300px;
`;
const TextInput = styled.input`
  grid-row: 2;
  width: 220px;
  height: 100px;
  margin-left: 16px;
  margin-right: 16px;
  text-align: center;
  border-radius: 4px;
`;

const Text = styled.div`
  grid-row: 2;
  grid-column: 2;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RecordButton = styled.div`
  height: 50px;
  width: 180px;
  align-self: center;
  justify-self: center;
  grid-row: 3;
  grid-column: 2;
  background: ${({ recording }: RecordingState) =>
    recording ? "#fff" : "#22313a"};
  color: ${({ recording }: RecordingState) => (recording ? "#22313a" : "#fff")};
  border: 1px solid #22313a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: lighter;
  cursor: pointer;
  user-select: none;
`;
const Counter = styled.div`
  height: 40px;
  width: 200px;
  background: #fff;
  grid-row: 1;
  grid-column: 3;
  align-self: center;
  border-radius: 4px;
  border: 1px solid #22313a;
  color: #22313a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;
const Audio = styled.audio`
  grid-row: 2;
  grid-column: 3;
`;
const Buttons = styled.div`
  grid-row: 3;
  grid-column: 3;
  align-self: center;
  justify-self: center;
  display: flex;
`;
const Params = styled.div`
  grid-row: 1;
  grid-column: 3;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const Language = styled.div`
  height: 40px;
  width: 200px;
  background: #fff;
  grid-row: 1;
  grid-column: 3;
  align-self: center;
  border-radius: 4px;
  border: 1px solid #22313a;
  color: #22313a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`;
const SaveNextButton = styled.div`
  align-self: center;
  border-radius: 4px;
  border: 1px solid #22313a;
  color: #22313a;
  width: 140px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
`;
const Form = styled.form`
  grid-row: 2;
  width: 220px;
  height: 100px;
  margin-left: 16px;
  margin-right: 16px;
  text-align: center;
  border-radius: 4px;
`;
const CancelNextButton = styled.div`
  align-self: center;
  border-radius: 4px;
  border: 1px solid #22313a;
  color: #22313a;
  width: 140px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 15px;
`;

const ResetButton = styled.div`
  align-self: center;
  border-radius: 4px;
  border: 1px solid #22313a;
  color: #22313a;
  width: 140px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const uploadToGCP = async (file: File, url: string) => {
  const options = {
    headers: {
      "Content-Type": "application/octet-stream",
    },
  };
  await axios.put(url, file, options);
};

const TextToSpeechAugmentation: React.FC<TextToSpeechAugmentationProps> =
  () => {
    const me = useMeQuery();
    const [texts, setTexts] = useState<string[]>([]);
    const [step, setStep] = useState(0);
    const [sourceId, setSourceId] = useState(v4());
    const [recording, setRecording] = useState(false);
    const [audioSource, setAudioSource] = useState("");
    const [duration, setDuration] = useState<{ start?: Date }>();
    const [language, setLanguage] = useState("FR");
    const recorder = useRef<RecordRTCPromisesHandler | null>(null);
    const blobRef = useRef<Blob | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const formRef = useRef<HTMLFormElement | null>(null);
    const streamRef = useRef<MediaStream | null>(null);

    const [signedUploadToGCP] = useSignedUploadToGcpMutation();
    const [createTTSAdminAnnotation] = useCreateAsrTtsAdminAnnotationMutation();

    const saveAndNext = async () => {
      if (blobRef.current) {
        const uuid = v4();
        const fileName = `asr/tts-admin/${uuid}.webm`;
        const file = new File([blobRef.current], fileName, {
          lastModified: new Date().getTime(),
          type: "audio/webm",
        });
        const totalDuration = Math.abs(
          (new Date().getTime() - duration!.start!.getTime()) / 1000
        );
        const signedResult = await signedUploadToGCP({
          variables: { fileName },
        });
        if (signedResult.data?.signedUploadToGCP) {
          await uploadToGCP(file, signedResult.data?.signedUploadToGCP);
          const speakerId = me.data?.me?.id!;
          await createTTSAdminAnnotation({
            variables: {
              data: {
                language,
                text: texts[step],
                url: `https://storage.cloud.google.com/sweez-datasets-q65sdf46sdfq51/${fileName}`,
                metadata: {
                  duration: totalDuration,
                  speakerId,
                  step,
                  sourceId,
                },
              },
            },
            context: { clientName: "datasets" },
          });
          recorder.current = null;
          blobRef.current = null;
          setAudioSource("");
          if (step + 1 === texts.length) {
            setStep(0);
            setTexts([]);
            setSourceId(v4());
            formRef.current?.reset();
          } else {
            setStep((step) => step + 1);
          }
        }
      }
    };

    useEffect(() => {
      (async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true,
        });
        streamRef.current = stream;
      })();
    }, []);

    return (
      <Container
        onKeyDown={async (e) => {
          if (e.key === "Enter" && me.data?.me) {
            e.preventDefault();
            if (!recording) {
              //start recording;
              const stream = streamRef.current!;
              recorder.current = new RecordRTCPromisesHandler(stream, {
                type: "audio",
                mimeType: "audio/webm",
              });
              recorder.current.startRecording();
              setDuration({ start: new Date() });
            } else {
              //stop recording
              await recorder.current?.stopRecording();

              const blob = await recorder.current?.getBlob();
              if (blob) {
                blobRef.current = blob;
                recorder.current?.reset();
                setAudioSource(URL.createObjectURL(blob));
              }
              await saveAndNext();
            }
            setRecording((recording) => !recording);
          }
        }}>
        <Form ref={formRef}>
          <TextInput
            placeholder="Copy/Paste text you want to read"
            onChange={(e) => {
              if (e.currentTarget.value !== "") {
                setTexts(e.currentTarget.value.split("."));
              }
            }}
          />
        </Form>
        <Params>
          {!!texts.length && <Counter>{`${step + 1}/${texts.length}`}</Counter>}
          <Language
            onClick={() => {
              if (language === "FR") {
                setLanguage("ENG");
              } else {
                setLanguage("FR");
              }
            }}>
            {language}
          </Language>
        </Params>

        <Text> {texts.length > 0 && texts[step]}</Text>
        <RecordButton
          recording={recording}
          onClick={async () => {
            if (!recording) {
              //start recording;
              const stream = await navigator.mediaDevices.getUserMedia({
                video: false,
                audio: true,
              });

              recorder.current = new RecordRTCPromisesHandler(stream, {
                type: "audio",
                mimeType: "audio/webm",
              });
              recorder.current.startRecording();
              setDuration({ start: new Date() });
            } else {
              //stop recording
              await recorder.current?.stopRecording();

              const blob = await recorder.current?.getBlob();
              if (blob) {
                blobRef.current = blob;
                recorder.current?.reset();
                setAudioSource(URL.createObjectURL(blob));
              }
            }
            setRecording((recording) => !recording);
          }}>
          {recording ? "STOP RECORDING" : "START RECORDING"}
        </RecordButton>
        <Audio ref={audioRef} src={audioSource} controls />
        <Buttons
          onKeyPress={async (e) => {
            console.log(e.key);
            if (e.key === "Enter") {
              await saveAndNext();
            }
          }}>
          <SaveNextButton
            onClick={async () => {
              await saveAndNext();
            }}>
            SAVE & NEXT
          </SaveNextButton>
          <CancelNextButton
            onClick={() => {
              if (!recording) {
                setStep((step) => step + 1);
              }
            }}>
            CANCEL & NEXT
          </CancelNextButton>
          <ResetButton
            onClick={() => {
              setStep(0);
              setTexts([]);
              setSourceId(v4());
              formRef.current?.reset();
            }}>
            RESET
          </ResetButton>
        </Buttons>
      </Container>
    );
  };

export default TextToSpeechAugmentation;
