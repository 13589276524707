import React, { useEffect, useRef } from "react";
import styled from "styled-components";

type Annotation = {
  step: number;
  context: string;
  metadata?: any;
  text: string;
  asrAnnotation: string;
  sourceId: string;
  language: string;
};

type TurnContextProps = {
  currentCtx: string;
  setCurrentCtx: any;
  step: number;
  id: number;
  contextAnnotations: Annotation[];
};

const Container = styled.textarea`
  width: 80%;
  height: 150px;
  margin-top: 20px;
`;
const TurnContext: React.FC<TurnContextProps> = ({
  id,
  contextAnnotations,
  currentCtx,
  setCurrentCtx,
  step,
}) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (step === id) {
      ref.current?.focus();
    }
  }, [id, step]);

  return (
    <Container
      ref={ref}
      value={
        contextAnnotations[id] ? contextAnnotations[id].context : currentCtx
      }
      placeholder={currentCtx}
      onChange={(e) => {
        setCurrentCtx(e.target.value);
      }}
    />
  );
};

export default TurnContext;
