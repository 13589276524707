import React from "react";
import styled from "styled-components";

type SpeakerTypeProps = {};

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  flex: 0.8;
  width: 100%;
  background: blue;
`;

const Controls = styled.div`
  flex: 0.2;
  width: 100%;
  background: green;
`;

const SpeakerType: React.FC<SpeakerTypeProps> = () => {
  return (
    <Container>
      <Content></Content>
      <Controls></Controls>
    </Container>
  );
};

export default SpeakerType;
