import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { Helmet } from "react-helmet";

const prodEndpointLink = new HttpLink({
  uri:
    process.env.NODE_ENV === "production"
      ? "https://backend.sweez.io/graphql"
      : "http://localhost:4000/graphql", //"http://localhost:4000/graphql"
  credentials: "include",
});

const datasetEndointLink = new HttpLink({
  uri:
    process.env.NODE_ENV === "production"
      ? "https://datasets-backend.sweez.io/graphql"
      : "http://localhost:4008/graphql", //"http://localhost:4000/graphql"
  credentials: "include",
  headers: { authorization: process.env.REACT_APP_FRONT_API_KEY },
});

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === "datasets",
    datasetEndointLink, // <= apollo will send to this if clientName is "third-party"
    prodEndpointLink // <= otherwise will send to this
  ),
  cache: new InMemoryCache(),
  credentials: "include",
});
//"https://backend.sweez.io/graphql"
//http://localhost:4000/graphql
ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin App Sweez</title>
        <link rel="canonical" href="https://admin.sweez.io" />
      </Helmet>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
