import React from "react";
import styled from "styled-components";

type SummariesProps = {};
const Container = styled.div`
  height: 100vh;
  flex: 90;
`;
const Summaries: React.FC<SummariesProps> = () => {
  return <Container>Summaries</Container>;
};

export default Summaries;
