import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  useRequiresAnnotationQuery,
  useSetAnnotationMutation,
  useCreateAsrProdAnnotationMutation,
} from "../../../../generated/graphql";
import { wordErrorRate } from "word-error-rate";
import ContentEditable from "react-contenteditable";
type SpeechRecognitionProps = {};
const Container = styled.div`
  height: 100vh;
  flex: 90;
  display: grid;
  grid-template-columns: 10vw 30vw 30vw 15vw;
  grid-template-rows: 10vh 20vh 20vh 20vh 10vh;
`;
const Text = styled.div`
  grid-column: 2/4;
  grid-row: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border: 1px solid #f1f1f1;
`;

const Annotation = styled(ContentEditable)`
  grid-column: 2/4;
  grid-row: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  border: 1px solid #f1f1f1;
`;
const Buttons = styled.div`
  grid-column: 2/4;
  grid-row: 6;
  display: flex;
  align-items: center;
`;

const NextButton = styled.div`
  width: 160px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #22313a;
  color: white;
  cursor: pointer;
  transition: ease-out 100ms;
  &:hover {
    transform: scale(1.02);
  }
`;
const PrevButton = styled.div`
  width: 160px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #22313a;
  color: white;
  cursor: pointer;
  transition: ease-out 100ms;
  margin-left: auto;
  margin-right: 20px;
  user-select: none;
  &:hover {
    transform: scale(1.02);
  }
`;
const SkipButton = styled.div`
  width: 80%;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #22313a;
  color: white;
  cursor: pointer;
  transition: ease-out 100ms;
  user-select: none;

  &:hover {
    transform: scale(1.02);
  }
`;
const Audio = styled.audio``;

const MetaData = styled.div`
  grid-column: 4;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TurnStep = styled.div`
  padding: 10px;
  width: 120px;
  border-radius: 4px;
  border: 1px solid #22313a;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Performance = styled.div`
  grid-column: 4;
  grid-row: 3/5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Player = styled.div`
  grid-row: 2;
  grid-column: 2/4;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Skip = styled.div`
  grid-row: 2;
  grid-column: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Language = styled.div`
  margin-top: 10px;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  width: 120px;
  border-radius: 4px;
  border: 1px solid #22313a;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const WER = styled.div``;

const SpeechRecognition: React.FC<SpeechRecognitionProps> = () => {
  const { data, refetch } = useRequiresAnnotationQuery({
    variables: { annotationType: "ASR" },
  });
  const [setAnnotationAsr] = useSetAnnotationMutation();
  const [pushAnnotationToDataset] = useCreateAsrProdAnnotationMutation();
  const annotationRef = useRef<any>();
  const [annotatedTurn, setAnnotatedTurn] = useState({
    id: "",
    url: "",
    text: "",
    annotation: "",
    duration: 0,
    signedUrl: "",
  });
  const [step, setStep] = useState(0);
  const [language, setLanguage] = useState("FR");
  const [wer, setWer] = useState(100);
  const [turns, setTurns] = useState<
    {
      id: string;
      url: string;
      text: string;
      annotation: string;
      wer: number;
      sourceId: string;
      language: string;
      duration: number;
      signedUrl?: string;
    }[]
  >([]);

  useEffect(() => {
    if (data?.requiresAnnotation.turns) {
      const id = data?.requiresAnnotation.turns[step]._id;
      const duration =
        Math.round(
          (data.requiresAnnotation.turns[step].end! -
            data.requiresAnnotation.turns[step].start!) *
            100
        ) / 100;
      const text = data?.requiresAnnotation.turns[step].text;
      const url = data?.requiresAnnotation.turns[step].url || "";
      const annotation = turns[step] ? turns[step].annotation : "";
      const signedUrl = data?.requiresAnnotation.turns[step].signedUrl
        ? data?.requiresAnnotation.turns[step].signedUrl!
        : "";

      setAnnotatedTurn({ id, text, url, annotation, signedUrl, duration });
    }
    annotationRef.current?.focus();
    console.log(turns[step - 1]);
  }, [data, step, turns]);

  useEffect(() => {
    setWer(wordErrorRate(annotatedTurn.text, annotatedTurn.annotation) * 100);
  }, [annotatedTurn.text, annotatedTurn.annotation]);

  return (
    <Container
      onKeyDown={async (e) => {
        console.log("key:", e.key);
        if (e.key === "PageDown") {
          if (step >= 1) {
            setStep((step) => step - 1);
          }
        } else if (e.key === "PageUp") {
          if (step < data?.requiresAnnotation.turns?.length! - 1) {
            setStep((step) => step + 1);
          }
        } else if (e.key === "Insert") {
          setAnnotatedTurn((annotatedTurn) => ({
            ...annotatedTurn,
            annotation: annotatedTurn.text.replace("<div><br></div>", ""),
          }));
          console.log(annotatedTurn);
        } else if (e.code === "Escape") {
          await setAnnotationAsr({
            variables: {
              idMeeting: data?.requiresAnnotation._id!,
              annotation: "rejected",
              type: "asr",
            },
          });
        } else if (e.code === "ControlLeft") {
          setLanguage((language) => {
            if (language === "FR") {
              return "ENG";
            } else {
              return "FR";
            }
          });
        } else if (e.key === "Enter") {
          if (step < data?.requiresAnnotation.turns?.length! - 1) {
            setTurns((turns) => {
              const turnIndex = turns.findIndex(
                (e) => e.id === annotatedTurn.id
              );
              if (turnIndex > -1) {
                turns[turnIndex] = {
                  ...annotatedTurn,
                  wer,
                  sourceId: data?.requiresAnnotation._id!,
                  language,
                };
                return [...turns];
              } else {
                return [
                  ...turns,
                  {
                    ...annotatedTurn,
                    wer,
                    sourceId: data?.requiresAnnotation._id!,
                    language,
                  },
                ];
              }
            });
            setStep((step) => step + 1);
          } else if (step === data?.requiresAnnotation.turns?.length! - 1) {
            setTurns((turns) => {
              const turnIndex = turns.findIndex(
                (e) => e.id === annotatedTurn.id
              );
              if (turnIndex > -1) {
                turns[turnIndex] = {
                  ...annotatedTurn,
                  wer,
                  sourceId: data?.requiresAnnotation._id!,
                  language,
                };
                return [...turns];
              } else {
                return [
                  ...turns,
                  {
                    ...annotatedTurn,
                    wer,
                    sourceId: data?.requiresAnnotation._id!,
                    language,
                  },
                ];
              }
            });
            //todo: push annotations, refetch & reset all
            const annotations = turns.map((turn, idx) => ({
              language,
              text: turn.annotation,
              url: turn.url,
              metadata: {
                wer: turn.wer,
                source: turn.sourceId,
                duration: turn.duration,
                step: idx,
              },
            }));
            annotations.push({
              language,
              text: annotatedTurn.annotation,
              url: annotatedTurn.url,
              metadata: {
                wer,
                source: data?.requiresAnnotation._id!,
                duration: annotatedTurn.duration,
                step: annotations.length,
              },
            });
            console.log("annotation");
            await pushAnnotationToDataset({
              variables: {
                data: annotations,
              },
              context: { clientName: "datasets" },
            });
            console.log("settig annotation");

            await setAnnotationAsr({
              variables: {
                idMeeting: data?.requiresAnnotation._id!,
                annotation: "accepted",
                type: "asr",
              },
            });

            //resets everything
            setAnnotatedTurn({
              annotation: "",
              id: "",
              text: "",
              url: "",
              duration: 0,
              signedUrl: "",
            });
            setTurns([]);
            setStep(0);

            await refetch();
          }
        }
      }}>
      <Skip>
        <SkipButton
          onClick={async () => {
            await setAnnotationAsr({
              variables: {
                idMeeting: data?.requiresAnnotation._id!,
                annotation: "rejected",
                type: "asr",
              },
            });

            //resets everything
            setAnnotatedTurn({
              annotation: "",
              id: "",
              text: "",
              url: "",
              duration: 0,
              signedUrl: "",
            });
            setTurns([]);
            setStep(0);

            await refetch();
          }}>
          Skip
        </SkipButton>
      </Skip>
      <Player>
        <Audio
          autoPlay
          controls
          src={
            annotatedTurn.signedUrl !== ""
              ? annotatedTurn.signedUrl
              : annotatedTurn.url
          }
          controlsList="nodownload"
        />
      </Player>
      <Text>{annotatedTurn.text}</Text>
      <Annotation
        innerRef={annotationRef}
        onChange={(e) => {
          //if (annotatedTurn.annotation === "")
          setAnnotatedTurn((annotatedTurn) => ({
            ...annotatedTurn,
            annotation: e.target.value.replace("<div><br></div>", ""),
          }));
        }}
        html={annotatedTurn.annotation}
      />
      <MetaData>
        <TurnStep>
          {step + 1}/{data?.requiresAnnotation.turns?.length}
        </TurnStep>
        <Language
          onClick={() => {
            setLanguage((language) => {
              if (language === "FR") {
                return "ENG";
              } else {
                return "FR";
              }
            });
          }}>
          {language}
        </Language>
      </MetaData>
      <Performance>
        <WER>WER {wer}%</WER>
      </Performance>
      <Buttons>
        <PrevButton
          onClick={() => {
            console.log("step:", step);
            if (step >= 1) {
              setStep((step) => step - 1);
            }
          }}>
          PREV
        </PrevButton>
        <NextButton
          onClick={async () => {
            if (step < data?.requiresAnnotation.turns?.length! - 1) {
              setTurns((turns) => {
                const turnIndex = turns.findIndex(
                  (e) => e.id === annotatedTurn.id
                );
                if (turnIndex > -1) {
                  turns[turnIndex] = {
                    ...annotatedTurn,
                    wer,
                    sourceId: data?.requiresAnnotation._id!,
                    language,
                  };
                  return [...turns];
                } else {
                  return [
                    ...turns,
                    {
                      ...annotatedTurn,
                      wer,
                      sourceId: data?.requiresAnnotation._id!,
                      language,
                    },
                  ];
                }
              });
              setStep((step) => step + 1);
            } else if (step === data?.requiresAnnotation.turns?.length! - 1) {
              setTurns((turns) => {
                const turnIndex = turns.findIndex(
                  (e) => e.id === annotatedTurn.id
                );
                if (turnIndex > -1) {
                  turns[turnIndex] = {
                    ...annotatedTurn,
                    wer,
                    sourceId: data?.requiresAnnotation._id!,
                    language,
                  };
                  return [...turns];
                } else {
                  return [
                    ...turns,
                    {
                      ...annotatedTurn,
                      wer,
                      sourceId: data?.requiresAnnotation._id!,
                      language,
                    },
                  ];
                }
              });
              //todo: push annotations, refetch & reset all
              const annotations = turns.map((turn, step) => ({
                language,
                text: turn.annotation,
                url: turn.url,
                metadata: {
                  wer: turn.wer,
                  source: turn.sourceId,
                  duration: turn.duration,
                  step,
                },
              }));

              annotations.push({
                language,
                text: annotatedTurn.annotation,
                url: annotatedTurn.url,
                metadata: {
                  wer,
                  source: data?.requiresAnnotation._id!,
                  duration: annotatedTurn.duration,
                  step: annotations.length,
                },
              });
              await pushAnnotationToDataset({
                variables: {
                  data: annotations,
                },
                context: { clientName: "datasets" },
              });

              await setAnnotationAsr({
                variables: {
                  idMeeting: data?.requiresAnnotation._id!,
                  annotation: "accepted",
                  type: "asr",
                },
              });

              //resets everything
              setAnnotatedTurn({
                annotation: "",
                id: "",
                text: "",
                url: "",
                duration: 0,
                signedUrl: "",
              });
              setTurns([]);
              setStep(0);

              await refetch();
            }
          }}>
          {step === data?.requiresAnnotation.turns?.length! - 1
            ? "SAVE"
            : "NEXT"}
        </NextButton>
      </Buttons>
    </Container>
  );
};

export default SpeechRecognition;
