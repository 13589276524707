import React from "react";
import styled from "styled-components";
import "../App.css";
import { Route } from "react-router-dom";
import NavBar from "./AuthenticatedApp/NavBar";
import Home from "./AuthenticatedApp/Home";
import SyntheticMeetings from "./AuthenticatedApp/SyntheticMeetings";
import NLI from "./AuthenticatedApp/Annotations/NLI";
import Sections from "./AuthenticatedApp/Annotations/Sections";
import Summaries from "./AuthenticatedApp/Annotations/Summaries";
import { RecoilRoot } from "recoil";
import Applications from "./AuthenticatedApp/Applications";
import Users from "./AuthenticatedApp/Users";
import UsersMeetings from "./AuthenticatedApp/Users/UsersMeetings";
import MeetingByNotionId from "./AuthenticatedApp/Users/MeetingByNotionId";
import UserAircall from "./AuthenticatedApp/Users/UserAircall";
import SpeakerType from "./AuthenticatedApp/Annotations/SpeakerType";
import Context from "./AuthenticatedApp/Annotations/Context";
import LM from "./AuthenticatedApp/Annotations/LM";
import Sentiment from "./AuthenticatedApp/Annotations/Sentiment";
import ASR from "./AuthenticatedApp/Annotations/ASR";

const Container = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 200px auto;
  grid-template-rows: 5vh 95vh;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Raleway", sans-serif;
  font-size: 24px;
`;

type AuthAppRights = {
  userRights: {
    annotator?: string;
    admin?: string;
  };
};

const AuthenticatedApp: React.FC<AuthAppRights> = ({ userRights }) => {
  return (
    <RecoilRoot>
      <Container>
        <Logo>Sweez 🍉 </Logo>
        <NavBar />
        <Route exact path="/">
          <Home />
        </Route>
        {userRights.admin && (
          <Route path="/users">
            <Users />
          </Route>
        )}
        {userRights.annotator && (
          <>
            <Route path="/synthetic-meetings">
              <SyntheticMeetings />
            </Route>
            <Route path="/speech-recognition">
              <ASR />
            </Route>
            <Route path="/speaker-detection">
              <SpeakerType />
            </Route>
            <Route path="/context">
              <Context />
            </Route>
            <Route path="/sections">
              <Sections />
            </Route>
            <Route path="/lm">
              <LM />
            </Route>
            <Route path="/sentiment">
              <Sentiment />
            </Route>
            <Route path="/nli">
              <NLI />
            </Route>
            <Route path="/summaries">
              <Summaries />
            </Route>
          </>
        )}
        {userRights.admin && (
          <Route path="/applications">
            <Applications />
          </Route>
        )}
        <Route path="/usermeetings/:id">
          <UsersMeetings />
        </Route>
        <Route path="/aircall/:id">
          <UserAircall />
        </Route>
        <Route path="/meetingById/:id">
          <MeetingByNotionId />
        </Route>
      </Container>
    </RecoilRoot>
  );
};

export default AuthenticatedApp;
