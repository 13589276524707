import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Audio,
  BlackButton,
  Buttons,
  Container,
  Header,
  Main,
  Separator,
  Speaker,
  Text,
  Transcription,
  Turn,
  WhiteButton,
} from "../../../components/NLPAnnotations";

import { useInsertSentimentsMutation } from "../../../generated/graphql";
import { useAnnotation } from "../../../utils/useAnnotations";

type SentimentProps = {};

type SentimentType = {
  sentiment: "POSITIVE" | "NEGATIVE" | "NEUTRAL";
};

type Annotation = {
  step: number;
  sentiment: "POSITIVE" | "NEGATIVE" | "NEUTRAL";
  metadata?: any;
  text: string;
  asrAnnotation: string;
  sourceId: string;
  language: string;
};

const TurnSentiment = styled.div`
  height: 20px;
  width: 20px;
  background: ${({ sentiment }: SentimentType) =>
    sentiment === "POSITIVE"
      ? "#53e053"
      : sentiment === "NEGATIVE"
      ? "#ff5e5e"
      : "grey"};
  border-radius: 100px;
  margin-left: auto;
  margin-right: 20px;
`;

const Sentiment: React.FC<SentimentProps> = () => {
  const {
    setAnnotated,
    language,
    setLanguage,
    step,
    setStep,
    stepRef,
    data,
    refetch,
    asrAnnotations,
  } = useAnnotation("sentiment");
  const [insertSentiments] = useInsertSentimentsMutation();
  const [sentimentAnnotations, setSentimentAnnotations] = useState<
    Annotation[]
  >([]);

  const manageAnnotations = (e: KeyboardEvent) => {
    if (data?.requiresAnnotation.turns) {
      console.log(data.requiresAnnotation.turns.length);
      if (e.key === "ArrowDown") {
        if (stepRef.current < data.requiresAnnotation.turns.length - 1) {
          setStep((step) => step + 1);
          stepRef.current = stepRef.current + 1;
        }
      } else if (e.key === "ArrowUp") {
        if (stepRef.current > 0) {
          stepRef.current = stepRef.current - 1;
          setStep((step) => step - 1);
        }
      } else if (e.key === "End") {
        setSentimentAnnotations((sentimentAnnotations) => {
          const annotatedTurn = sentimentAnnotations.findIndex(
            (e) => e.step === stepRef.current
          );
          if (annotatedTurn > -1) {
            sentimentAnnotations[annotatedTurn].sentiment = "NEUTRAL";
            return [...sentimentAnnotations];
          } else {
            const newAnnotatedTurn: Annotation = {
              step: stepRef.current,
              sentiment: "NEUTRAL",
              asrAnnotation:
                asrAnnotations.data?.asrProdbySource[stepRef.current]?.text ||
                "",
              language,
              sourceId: data.requiresAnnotation._id,
              text: data?.requiresAnnotation.turns![stepRef.current].text || "",
            };
            return [...sentimentAnnotations, newAnnotatedTurn];
          }
        });
        if (stepRef.current < data?.requiresAnnotation.turns.length - 1) {
          setStep((step) => step + 1);
          stepRef.current = stepRef.current + 1;
        }
      } else if (e.key === "ArrowRight") {
        setSentimentAnnotations((sentimentAnnotations) => {
          const annotatedTurn = sentimentAnnotations.findIndex(
            (e) => e.step === stepRef.current
          );
          if (annotatedTurn > -1) {
            sentimentAnnotations[annotatedTurn].sentiment = "POSITIVE";
            return [...sentimentAnnotations];
          } else {
            const newAnnotatedTurn: Annotation = {
              step: stepRef.current,
              sentiment: "POSITIVE",
              asrAnnotation:
                asrAnnotations.data?.asrProdbySource[stepRef.current]?.text ||
                "",
              language,
              sourceId: data.requiresAnnotation._id,
              text: data?.requiresAnnotation.turns![stepRef.current].text || "",
            };
            return [...sentimentAnnotations, newAnnotatedTurn];
          }
        });
        if (stepRef.current < data?.requiresAnnotation.turns.length - 1) {
          setStep((step) => step + 1);
          stepRef.current = stepRef.current + 1;
        }
      } else if (e.key === "ArrowLeft") {
        setSentimentAnnotations((sentimentAnnotations) => {
          const annotatedTurn = sentimentAnnotations.findIndex(
            (e) => e.step === stepRef.current
          );
          if (annotatedTurn > -1) {
            sentimentAnnotations[annotatedTurn].sentiment = "NEGATIVE";
            return [...sentimentAnnotations];
          } else {
            const newAnnotatedTurn: Annotation = {
              step: stepRef.current,
              sentiment: "NEGATIVE",
              asrAnnotation:
                asrAnnotations.data?.asrProdbySource[stepRef.current]?.text ||
                "",
              language,
              sourceId: data.requiresAnnotation._id,
              text: data?.requiresAnnotation.turns![stepRef.current].text || "",
            };
            return [...sentimentAnnotations, newAnnotatedTurn];
          }
        });
        if (stepRef.current < data?.requiresAnnotation.turns.length - 1) {
          setStep((step) => step + 1);
          stepRef.current = stepRef.current + 1;
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", manageAnnotations);
    return () => {
      document.removeEventListener("keydown", manageAnnotations);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Container>
      Sentiment
      <Header>Sentiment Annotation</Header>
      <Main
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setStep((step) => step + 1);
          }
        }}>
        {data?.requiresAnnotation.turns?.map((turn, id) => (
          <Turn key={turn._id} focused={id === step}>
            <Speaker>{turn.speaker}</Speaker>
            <Text>
              <Transcription>{turn.text}</Transcription>
              <Separator />
              <Transcription>
                {asrAnnotations.data?.asrProdbySource[id]
                  ? asrAnnotations.data?.asrProdbySource[id].text
                  : "No Annotations"}
              </Transcription>
              <Separator />
              <Audio
                controls
                src={!!turn.signedUrl ? turn.signedUrl! : turn.url!}
                controlsList="nodownload"
              />
            </Text>
            <TurnSentiment
              sentiment={sentimentAnnotations[id]?.sentiment || "NEUTRAL"}
            />
          </Turn>
        ))}
      </Main>
      <Buttons>
        <WhiteButton
          onClick={() => {
            setLanguage((language) => (language === "FR" ? "ENG" : "FR"));
          }}>
          {language}
        </WhiteButton>
        <BlackButton
          onClick={async () => {
            if (
              sentimentAnnotations.length !==
              data?.requiresAnnotation.turns?.length
            ) {
              alert("Annotate everything first please");
            } else {
              const sentimentInput = sentimentAnnotations.map((turn) => ({
                language,
                text: turn.text,
                sentiment: turn.sentiment,
                step: turn.step,
                sourceId: turn.sourceId,
                metadata: { asrAnnotation: turn.asrAnnotation },
              }));
              await insertSentiments({
                variables: { data: sentimentInput },
                context: { clientName: "datasets" },
              });
              await setAnnotated({
                variables: {
                  idMeeting: data.requiresAnnotation._id,
                  annotation: "annotated",
                  type: "sentiment",
                },
              });
              await refetch();
            }
          }}>
          SAVE
        </BlackButton>
        <BlackButton
          onClick={async () => {
            setSentimentAnnotations([]);
            setStep(0);
            stepRef.current = 0;
            await refetch();
          }}
          style={{ marginTop: "auto", marginBottom: "10px" }}>
          CANCEL
        </BlackButton>
      </Buttons>
    </Container>
  );
};

export default Sentiment;
