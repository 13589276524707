import React from "react";
import styled from "styled-components";
type HomeProps = {};
const Container = styled.div`
  height: 100vh;
  flex: 90;
`;

const Home: React.FC<HomeProps> = () => {
  return <Container>Home</Container>;
};

export default Home;
