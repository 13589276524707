import React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { useMeetingsByUserQuery } from "../../../generated/graphql";

type UsersMeetingsProps = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MeetingName = styled.div``;

const MeetingDate = styled.div``;
const MeetingSummary = styled.div``;
const MeetingContainer = styled.div`
  margin-top: 40px;
  cursor: pointer;
`;
const UsersMeetings: React.FC<UsersMeetingsProps> = () => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const { data } = useMeetingsByUserQuery({
    variables: { id: parseFloat(id) },
  });
  return (
    <Container>
      {data?.meetingsByUser.map((meeting) => (
        <MeetingContainer
          onClick={() => history.push(`/meetingById/${meeting.notionId}`)}>
          <MeetingName>{meeting.title}</MeetingName>
          <MeetingDate>{meeting.date}</MeetingDate>
          <MeetingSummary>{meeting.long_summary}</MeetingSummary>
        </MeetingContainer>
      ))}
    </Container>
  );
};

export default UsersMeetings;
