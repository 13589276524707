import React from "react";
import styled from "styled-components";

type SyntheticMeetingsProps = {};
const Container = styled.div`
  height: 100vh;
  flex: 90;
`;
const SyntheticMeetings: React.FC<SyntheticMeetingsProps> = () => {
  return <Container>Synthetic Meetings</Container>;
};

export default SyntheticMeetings;
