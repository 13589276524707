import React, { useState } from "react";
import styled from "styled-components";
import { useAnnotation } from "../../../utils/useAnnotations";
import {
  Audio,
  BlackButton,
  Buttons,
  Container,
  Header,
  Main,
  Separator,
  Speaker,
  Text,
  Transcription,
  Turn,
  WhiteButton,
} from "../../../components/NLPAnnotations";
import { v4 } from "uuid";
import SectionInput from "./Sections/SectionInput";
import Select from "react-select";
import { useInsertSectionsMutation } from "../../../generated/graphql";

type SectionsProps = {};
type SectionType = {
  [key: string]: { [step: number]: { content: string; id: string }[] };
};

type TurnSectiontype = {
  step: number;
  type: string;
};

type Annotation = {
  step: number;
  sections: {
    type: string;
    content: string;
  }[];
  metadata?: any;
  text: string;
  sourceId: string;
  language: string;
};

const TurnSections = styled.div`
  width: 80%;
  height: auto;
  margin-top: 20px;
`;

const TurnSection = styled.div`
  display: flex;
`;
const SectionTypeValue = styled.div`
  text-align: center;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 50px;
  width: 150px;
`;

const SectionContents = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  margin-top: 20px;
`;

const SectionAddButton = styled.div`
  margin-left: auto;
  margin-top: 30px;
  width: 100px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #22313a;
  color: white;
  cursor: pointer;
  transition: ease-out 100ms;
  user-select: none;
  &:hover {
    transform: scale(1.02);
  }
`;
const ElementContainer = styled.div`
  display: flex;
`;

const Sections: React.FC<SectionsProps> = () => {
  const {
    setAnnotated,
    language,
    setLanguage,
    step,
    setStep,
    stepRef,
    data,
    refetch,
    asrAnnotations,
  } = useAnnotation("sections");
  const [rerenders, setRerenders] = useState(0);
  const [sections, setSections] = useState<SectionType>({});
  const [turnSections, setTurnSections] = useState<TurnSectiontype[]>([]);
  const [options, setOptions] = useState<{ value: string; label: string }[]>([
    { value: "A", label: "A" },
    { value: "B", label: "B" },
  ]);
  const [insertSections] = useInsertSectionsMutation();
  console.log(setOptions);
  return (
    <Container>
      <Header>Sections Annotation - {rerenders}</Header>
      <Main>
        {data?.requiresAnnotation.turns?.map((turn, id) => (
          <Turn direction="column" key={turn._id} focused={id === step}>
            <Speaker>{turn.speaker}</Speaker>
            <Text>
              <Transcription>{turn.text}</Transcription>
              <Separator />
              <Transcription>
                {asrAnnotations.data?.asrProdbySource[id]
                  ? asrAnnotations.data?.asrProdbySource[id].text
                  : "No Annotations"}
              </Transcription>
              <Separator />
              <Audio
                controls
                src={!!turn.signedUrl ? turn.signedUrl! : turn.url!}
                controlsList="nodownload"
              />
            </Text>
            <TurnSections>
              {" "}
              <Select
                isMulti
                name="colors"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(turnSections) => {
                  const turnTypes = turnSections.map((e) => e.label);
                  setTurnSections((oldTurnSections) => {
                    const newTurnSections = oldTurnSections.filter(
                      (e) => e.step !== id
                    );
                    turnTypes.forEach((turnType) => {
                      newTurnSections.push({ step: id, type: turnType });
                    });
                    return newTurnSections;
                  });

                  //TODO: Find max ID where turnType has been selected
                  //TODO: set up id to id_lim_max
                  setSections((sections) => {
                    const newSections: SectionType = JSON.parse(
                      JSON.stringify(sections)
                    );
                    turnSections.forEach((turnSection) => {
                      console.log("sections:", turnSection.label);
                      //TODO: find the max ID where the label exists
                      let previousIndex = 0;
                      if (newSections[turnSection.label]) {
                        for (var key in newSections[turnSection.label]) {
                          previousIndex = parseFloat(key);
                        }
                      }
                      if (
                        newSections[turnSection.label] &&
                        newSections[turnSection.label][previousIndex]
                      ) {
                        newSections[turnSection.label][id] =
                          newSections[turnSection.label][previousIndex];
                      } else {
                        if (newSections[turnSection.label]) {
                          newSections[turnSection.label][id] = [
                            { id: "init", content: "" },
                          ];
                        } else {
                          console.log("bru", turnSection.label);

                          newSections[turnSection.label] = {};
                          newSections[turnSection.label][id] = [
                            {
                              id: "init",
                              content: "",
                            },
                          ];
                          console.log(newSections[turnSection.label]);
                        }
                      }
                    });
                    console.log("new secitons", newSections);
                    return newSections;
                  });
                  //Set current Turn section type to max value

                  /*
                  console.log(rerenders);
                  setSections((oldSections) => {
                    setRerenders((rerender) => rerender + 1);
                    turnSections.forEach((turnSection) => {
                      if (!!oldSections[turnSection.label]) {
                        oldSections[turnSection.label].push({
                          step: id,
                          content: "",
                        });
                      } else {
                        oldSections[turnSection.label] = [
                          { step: id, content: "" },
                        ];
                      }
                    });

                    Object.keys(oldSections).forEach((section) => {});

                    return oldSections;
                    */
                  setRerenders((o) => o + 1);
                }}
              />
              {turnSections.map(
                (section) =>
                  section.step === id && (
                    <TurnSection key={`${section.type}-${section.step}`}>
                      <SectionTypeValue>{section.type}</SectionTypeValue>
                      <SectionContents>
                        {sections[section.type] &&
                          sections[section.type][id] &&
                          sections[section.type][id].map((elem) => (
                            <ElementContainer>
                              <SectionInput
                                content={elem.content}
                                id={id}
                                setSections={setSections}
                                key={elem.id}
                                elem={elem}
                                section={section}
                              />
                            </ElementContainer>
                          ))}
                        <SectionAddButton
                          onClick={(e) => {
                            e.preventDefault();
                            setSections((sections) => {
                              const newSections = JSON.parse(
                                JSON.stringify(sections)
                              );
                              if (
                                sections[section.type] &&
                                sections[section.type][id] &&
                                sections[section.type][id].length > 0
                              ) {
                                const uuid = v4();
                                newSections[section.type][id] = [
                                  ...sections[section.type][id],
                                  {
                                    id: uuid,
                                    step: id,
                                    content: "",
                                  },
                                ];
                              } else {
                                const uuid = v4();
                                newSections[section.type][id] = [
                                  {
                                    id: uuid,
                                    step: id,
                                    content: "",
                                  },
                                ];
                              }
                              console.log("sections updated", newSections);

                              return newSections;
                            });
                            setRerenders((o) => o + 1);
                          }}>
                          ADD
                        </SectionAddButton>
                      </SectionContents>
                    </TurnSection>
                  )
              )}
            </TurnSections>
          </Turn>
        ))}
      </Main>
      <Buttons>
        <WhiteButton
          onClick={() => {
            setLanguage((language) => (language === "FR" ? "ENG" : "FR"));
          }}>
          {language}
        </WhiteButton>
        <BlackButton
          onClick={async () => {
            const sectionsInput: Annotation[] =
              data!.requiresAnnotation.turns!.map((turn, index) => {
                let currentSections: {
                  type: string;
                  content: string;
                }[] = [];
                Object.keys(sections).forEach((section) => {
                  if (sections[section][index]) {
                    const result = sections[section][index].map((e) => ({
                      type: section,
                      content: e.content,
                    }));
                    currentSections = [...currentSections, ...result];
                  }
                });
                console.log("--------------------");
                currentSections.map((e, idx) =>
                  console.log(`test - ${e.type}: ${e.content}`)
                );
                return {
                  language,
                  sections: currentSections,
                  sourceId: data?.requiresAnnotation._id!,
                  step: index,
                  text: turn.text,
                  metadata: {
                    asrAnnotation: asrAnnotations.data?.asrProdbySource[index],
                  },
                };
              });
            console.log(sectionsInput);

            await insertSections({
              variables: { data: sectionsInput },
              context: { clientName: "datasets" },
            });
            await setAnnotated({
              variables: {
                idMeeting: data?.requiresAnnotation._id!,
                annotation: "annotated",
                type: "sections",
              },
            });
            await refetch();
          }}>
          SAVE
        </BlackButton>
        <BlackButton
          onClick={async () => {
            setSections({});
            setStep(0);
            stepRef.current = 0;
            await refetch();
          }}
          style={{ marginTop: "auto", marginBottom: "10px" }}>
          CANCEL
        </BlackButton>
      </Buttons>
    </Container>
  );
};

export default Sections;
