import React, { useState } from "react";
import styled from "styled-components";
import { useAnnotation } from "../../../utils/useAnnotations";
import {
  Audio,
  BlackButton,
  Buttons,
  Container,
  Header,
  Main,
  Separator,
  Speaker,
  Text,
  Transcription,
  Turn,
  WhiteButton,
} from "../../../components/NLPAnnotations";
import CreatableSelect from "react-select/creatable";
import { useInsertThematicsMutation } from "../../../generated/graphql";

type NLIProps = {};
type NLIType = {
  id: number;
  entity: string;
};

type Annotation = {
  step: number;
  thematic: string;
  metadata?: any;
  text: string;
  asrAnnotation?: string;
  sourceId: string;
  language: string;
};

const TurnSections = styled.div`
  width: 80%;
  height: auto;
  margin-top: 20px;
`;

const NLI: React.FC<NLIProps> = () => {
  const {
    setAnnotated,
    language,
    setLanguage,
    step,
    setStep,
    stepRef,
    data,
    refetch,
    asrAnnotations,
  } = useAnnotation("thematics");

  const [nli, setNLI] = useState<NLIType[]>([]);
  const [options, setOptions] = useState<string[]>([
    "Speech Recognition",
    "Battery",
  ]);
  //const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [insertThematics] = useInsertThematicsMutation();
  console.log(nli);
  return (
    <Container>
      <Header>Sections Annotation</Header>
      <Main>
        {data?.requiresAnnotation.turns?.map((turn, id) => (
          <Turn direction="column" key={turn._id} focused={id === step}>
            <Speaker>{turn.speaker}</Speaker>
            <Text>
              <Transcription>{turn.text}</Transcription>
              <Separator />
              <Transcription>
                {asrAnnotations.data?.asrProdbySource[id]
                  ? asrAnnotations.data?.asrProdbySource[id].text
                  : "No Annotations"}
              </Transcription>
              <Separator />
              <Audio
                controls
                src={!!turn.signedUrl ? turn.signedUrl! : turn.url!}
                controlsList="nodownload"
              />
            </Text>
            <TurnSections>
              {" "}
              <CreatableSelect
                isMulti
                name="colors"
                options={options.map((e) => ({ label: e, value: e }))}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) =>
                  setNLI((secs) => {
                    console.log("changed", e);

                    const newNLI: NLIType[] = [];
                    e.forEach((elem) => {
                      if (!options.some((a) => a === elem.label)) {
                        setOptions((options) => [...options, elem.label]);
                      }
                      const isInSecs = secs.find(
                        (a) => a.id === id && a.entity === elem.label
                      );
                      if (!isInSecs) {
                        newNLI.push({
                          id,
                          entity: elem.label,
                        });
                      }
                    });

                    const newSecs = secs.filter(
                      (a) =>
                        a.id !== id ||
                        (a.id === id && !!e.find((b) => b.label === a.entity))
                    );
                    if (newNLI.length > 0) {
                      return [...newSecs, ...newNLI];
                    } else return [...newSecs];
                  })
                }
              />
            </TurnSections>
          </Turn>
        ))}
      </Main>
      <Buttons>
        <WhiteButton
          onClick={() => {
            setLanguage((language) => (language === "FR" ? "ENG" : "FR"));
          }}>
          {language}
        </WhiteButton>
        <BlackButton
          onClick={async () => {
            const thematicsInput: Annotation[] = nli.map((entity) => ({
              language,
              text:
                (data?.requiresAnnotation.turns as any)[entity.id]?.text || "",
              thematic: entity.entity,
              step: entity.id,
              sourceId: data?.requiresAnnotation._id || "",
              metadata: {
                asrAnnotation:
                  asrAnnotations.data?.asrProdbySource[entity.id]?.text || "",
              },
            }));
            console.log("thematics", thematicsInput);
            await insertThematics({
              variables: { data: thematicsInput },
              context: { clientName: "datasets" },
            });
            await setAnnotated({
              variables: {
                idMeeting: data?.requiresAnnotation._id!,
                annotation: "annotated",
                type: "thematics",
              },
            });
            await refetch();
          }}>
          SAVE
        </BlackButton>
        <BlackButton
          onClick={async () => {
            setNLI([]);
            setStep(0);
            stepRef.current = 0;
            await refetch();
          }}
          style={{ marginTop: "auto", marginBottom: "10px" }}>
          CANCEL
        </BlackButton>
      </Buttons>
    </Container>
  );
};

export default NLI;
