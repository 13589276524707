import { useRef, useState } from "react";
import {
  useAsrProdbySourceQuery,
  useRequiresAnnotationQuery,
  useSetAnnotationMutation,
} from "../generated/graphql";

export const useAnnotation = (annotationType: string) => {
  const [setAnnotated] = useSetAnnotationMutation();
  const [language, setLanguage] = useState("FR");
  const [step, setStep] = useState(0);
  const { data, refetch } = useRequiresAnnotationQuery({
    variables: { annotationType },
  });
  const stepRef = useRef(0);
  const asrAnnotations = useAsrProdbySourceQuery({
    variables: {
      source: data?.requiresAnnotation._id!,
    },
    context: { clientName: "datasets" },
  });

  return {
    setAnnotated,
    language,
    setLanguage,
    step,
    setStep,
    stepRef,
    data,
    refetch,
    asrAnnotations,
  };
};
