import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
type NavBarProps = {};
type Active = {
  active: boolean;
};
const Container = styled.div`
  flex: 10;
  height: 95vh;
  font-family: "Raleway", sans-serif;
  border-right: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  border-radius: 0px 20px 0px 0px;
  background: #22313a;
  grid-column: 1;
  grid-row: 2;
`;

const Button = styled(Link)`
  height: 4vh;
  vertical-align: middle;
  line-height: 4vh;
  cursor: pointer;
  padding-left: 15px;
  color: ${({ active }: Active) => (active ? "#22313a" : "#f1f1f1")};
  background: ${({ active }: Active) => (active ? "#f1f1f1" : "#22313a")};
  text-decoration: none;
  font-size: 14px;

  &:hover {
    background: #f1f1f1;
    color: #22313a;
  }
`;
const Section = styled.div`
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  margin-left: 15px;
  height: 4vh;
  color: #fdedee;
  font-weight: 600;
  font-size: 21px;
`;

const NavBar: React.FC<NavBarProps> = () => {
  const [active, setActive] = useState("");
  return (
    <Container>
      <Section>
        <Title>General</Title>
        {/*<Button to="/meetings">Meetings</Button>*/}
        <Button
          onClick={() => setActive("/users")}
          active={active === "/users"}
          to="/users">
          Users
        </Button>
      </Section>
      {
        <Section>
          <Title>Annotations</Title>
          <Button
            onClick={() => setActive("/speech-recognition")}
            active={active === "/speech-recognition"}
            to="/speech-recognition">
            Speech Recognition
          </Button>
          {/*<Button
            onClick={() => setActive("/speaker-detection")}
            active={active === "/speaker-detection"}
            to="/speaker-detection">
            Speaker Detection
          </Button>*/}
          {/*<Button
            onClick={() => setActive("/lm")}
            active={active === "/lm"}
            to="/lm">
            LM
          </Button>
          <Button
            onClick={() => setActive("/summaries")}
            active={active === "/summaries"}
            to="/summaries">
            Summaries
          </Button>
          */}

          <Button
            onClick={() => setActive("/sections")}
            active={active === "/sections"}
            to="/sections">
            Sections
          </Button>
          <Button
            onClick={() => setActive("/nli")}
            active={active === "/nli"}
            to="/nli">
            Thematics
          </Button>
          <Button
            onClick={() => setActive("/context")}
            active={active === "/context"}
            to="/context">
            Context
          </Button>
          <Button
            onClick={() => setActive("/sentiment")}
            active={active === "/sentiment"}
            to="/sentiment">
            Sentiment
          </Button>
        </Section>
      }
      <Section>
        <Title>Admin</Title>
        <Button
          onClick={() => setActive("/applications")}
          active={active === "/applications"}
          to="/applications">
          Applications
        </Button>
      </Section>
    </Container>
  );
};

export default NavBar;
