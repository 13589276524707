import React from "react";
import "./App.css";
import { useMeQuery } from "./generated/graphql";
import { BrowserRouter as Router } from "react-router-dom";
import AuthenticatedApp from "./screens/AuthenticatedApp";
import UnAuthenticatedApp from "./screens/UnAuthenticatedApp";

function App() {
  const { data } = useMeQuery();
  return (
    <Router>
      {data?.me?.userRights && data.me.userRights.internal ? (
        <AuthenticatedApp userRights={data.me.userRights} />
      ) : (
        <UnAuthenticatedApp />
      )}
    </Router>
  );
}

export default App;
