import React from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import {
  useAdminSetWebHookEndpointMutation,
  useAircallStatusQuery,
  useCallsMutation,
} from "../../../generated/graphql";

type UserAircallProps = {};

const Container = styled.div`
  font-family: "Raleway", sans-serif;
`;
const AircallSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NoAirCallContainer = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Username = styled.div`
  height: 50px;
  width: 200px;

  font-size: 20px;
  letter-spacing: 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Endpoint = styled.div`
  margin-top: 15px;
  height: 50px;
  width: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
`;
const Buttons = styled.div`
  margin-top: 25px;
  height: 50px;
  width: 400px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
`;

const SetEndpoint = styled.div`
  height: 40px;
  width: 150px;
  background: #0c1f2b;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 11px;
  cursor: pointer;
`;

const GatherOldCalls = styled.div`
  height: 40px;
  width: 150px;
  background: #0c1f2b;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 11px;
  cursor: pointer;
`;

const UserAircall: React.FC<UserAircallProps> = () => {
  const { id } = useParams<{ id: string }>();
  console.log("ID", id);
  const { data, error } = useAircallStatusQuery({
    variables: { userId: parseFloat(id) },
  });
  console.log("error:", error);
  const [callsMutation] = useCallsMutation();
  const [adminSetWebHookEndpoint] = useAdminSetWebHookEndpointMutation();
  //todo: load aircall data, status, webhook status, and upload files.
  return (
    <Container>
      {data?.aircallStatus.aircallIntegration ? (
        <AircallSubContainer>
          <Username>{data?.aircallStatus.username}</Username>
          <Endpoint>
            {data?.aircallStatus.webHookEndpoint
              ? "ENDPOINT OK"
              : "NO ENDPOINT"}
          </Endpoint>
          <Buttons>
            <SetEndpoint
              onClick={async () => {
                //todo: setEndpointMutation
                await adminSetWebHookEndpoint({
                  variables: {
                    userId: parseFloat(id),
                    aircallToken: data?.aircallStatus.aircallToken!,
                  },
                });
              }}>
              SET ENDPOINT
            </SetEndpoint>
            <GatherOldCalls
              onClick={async () => {
                await callsMutation({
                  variables: {
                    userId: parseFloat(id),
                    token: data?.aircallStatus.aircallToken,
                    all: true,
                  },
                });
                //todo: gather old calls & process them up
              }}>
              GATHER OLD CALLS
            </GatherOldCalls>
          </Buttons>
        </AircallSubContainer>
      ) : (
        <NoAirCallContainer>DOESNT HAVE AIRCALL</NoAirCallContainer>
      )}
    </Container>
  );
};

export default UserAircall;
