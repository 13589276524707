import React from "react";
import styled from "styled-components";

type LMProps = {};

const Container = styled.div``;

const LM: React.FC<LMProps> = () => {
  return <Container>LM</Container>;
};

export default LM;
