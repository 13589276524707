import React from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { MeDocument, MeQuery, useLoginMutation } from "../generated/graphql";
import { useHistory } from "react-router-dom";

type UnAuthenticatedAppProps = {};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Login = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  border: 1px solid #f1f1f1;
  height: 400px;
  width: 600px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 100px;
`;
const Input = styled.input`
  outline: none;
  width: 200px;
`;
const Label = styled.label`
  font-family: "Raleway", sans-serif;
`;

const Button = styled.button`
  height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #22313a;
  color: #22313a;
  cursor: pointer;
  background: #fff;
`;

const UnAuthenticatedApp: React.FC<UnAuthenticatedAppProps> = () => {
  const history = useHistory();
  const [login] = useLoginMutation();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async ({ email, password }) => {
      const user = await login({
        variables: {
          usernameOrEmail: email,
          password,
        },
        update: (cache, { data }) => {
          cache.writeQuery<MeQuery>({
            query: MeDocument,
            data: {
              __typename: "Query",
              me: data?.login.user,
            },
          });
        },
      });
      if (user.data) {
        history.push("/");
      }
    },
  });
  return (
    <Container>
      <Login onSubmit={formik.handleSubmit}>
        <InputContainer>
          <Label>Email</Label>
          <Input
            type="email"
            id="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </InputContainer>
        <InputContainer>
          <Label>Password</Label>
          <Input
            type="password"
            id="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </InputContainer>
        <Button type="submit">Go</Button>
      </Login>
    </Container>
  );
};

export default UnAuthenticatedApp;
