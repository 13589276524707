import React, { useState } from "react";
import styled from "styled-components";
import ContentEditable from "react-contenteditable";
type Saved = {
  saved: boolean;
};

type SectionType = {
  [key: string]: { [step: number]: { content: string; id: string }[] };
};
type TurnSectiontype = {
  step: number;
  type: string;
};
type SectionInputProps = {
  content: string;
  id: number;
  setSections: React.Dispatch<React.SetStateAction<SectionType>>;
  section: TurnSectiontype;
  elem: {
    content: string;
    id: string;
  };
};

const SectionInputComponent = styled(ContentEditable)`
  width: 80%;
  height: 50px;
  margin-top: 20px;
  text-align: left;
  line-height: 50px;
  padding-left: 10px;
  border: 1px solid #f1f1f1;
  font-size: 10px;
  border: 1px solid ${({ saved }: Saved) => (saved ? "green" : "#f1f1f1")};
`;

const SectionElemButton = styled.div`
  margin-top: 20px;

  margin-left: auto;
  width: 40px;
  height: 50px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #22313a;
  color: white;
  cursor: pointer;
  transition: ease-out 100ms;
  user-select: none;
  &:hover {
    transform: scale(1.02);
  }
`;

const SectionInput: React.FC<SectionInputProps> = ({
  id,
  content,
  setSections,
  section,
  elem,
}) => {
  const [buffer, setBuffer] = useState<string>("");
  const [rerenders, setRerenders] = useState(0);
  console.log(rerenders);
  //  const [saved, setSaved] = useState(false);
  return (
    <>
      {" "}
      <SectionInputComponent
        saved
        html={content ? content : buffer}
        key={id}
        onChange={(e) => {
          setBuffer(e.target.value);
        }}
      />
      <SectionElemButton
        onClick={(e) => {
          setBuffer("");
        }}>
        X
      </SectionElemButton>
      <SectionElemButton
        onClick={() => {
          setBuffer("");
          setSections((sections) => {
            const newSections: SectionType = JSON.parse(
              JSON.stringify(sections)
            );
            const index = newSections[section.type][id].findIndex(
              (e) => e.id === elem.id
            );
            newSections[section.type][id][index] = {
              id: elem.id,
              content: "",
            };
            return newSections;
          });
          setRerenders((o) => o + 1);
        }}>
        -
      </SectionElemButton>
      <SectionElemButton
        onClick={() => {
          setSections((sections) => {
            const newSections: SectionType = JSON.parse(
              JSON.stringify(sections)
            );
            const index = newSections[section.type][id].findIndex(
              (e) => e.id === elem.id
            );
            newSections[section.type][id][index] = {
              id: elem.id,
              content: buffer,
            };
            return newSections;
          });
          setBuffer("");
          setRerenders((o) => o + 1);
        }}>
        +
      </SectionElemButton>
    </>
  );
};

export default SectionInput;
