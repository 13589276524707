import styled from "styled-components";

type Focused = {
  focused: boolean;
  direction?: string;
};

export const Container = styled.div`
  display: grid;
  grid-template-columns: 10% 70% 20%;
  grid-template-rows: 10% 90%;
  height: 100vh;
`;
export const Header = styled.div`
  margin-top: 70px;
  grid-row: 1;
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Main = styled.div`
  margin-top: 70px;
  grid-row: 2;
  grid-column: 2;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

export const Buttons = styled.div`
  grid-column: 3;
  grid-row: 1/3;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BlackButton = styled.div`
  margin-top: 30px;
  width: 80%;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #22313a;
  color: white;
  cursor: pointer;
  transition: ease-out 100ms;
  user-select: none;
  &:hover {
    transform: scale(1.02);
  }
`;

export const WhiteButton = styled.div`
  margin-top: 30px;
  width: 80%;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid #22313a;
  color: #22313a;
  cursor: pointer;
  transition: ease-out 100ms;
  user-select: none;
  &:hover {
    transform: scale(1.02);
  }
`;

export const Turn = styled.div`
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: ${({ focused }: Focused) => (focused ? "2px" : "1px")} solid
    ${({ focused }: Focused) => (focused ? "#37acf5" : "#f1f1f1")};
  color: #22313a;
  padding: 10px;
  margin: 10px;
  margin-top: 25px;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  line-height: 22px;
  letter-spacing: 1px;
  flex-direction: ${({ direction }: Focused) =>
    direction === "column" ? "column" : "row"};
`;

export const Speaker = styled.div``;

export const Text = styled.div`
  width: 80%;
  margin-left: 50px;
  text-align: justify;
  display: flex;
  flex-direction: column;
`;
export const Transcription = styled.div`
  padding: 10px;
  padding-top: 20px;
`;
export const Separator = styled.div`
  height: 1px;
  background: #d1d1d1;
`;

export const Audio = styled.audio`
  margin-top: 25px;
`;
