import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AsrfrInput = {
  text: Scalars['String'];
  url: Scalars['String'];
  source: Scalars['String'];
  sourceId: Scalars['Int'];
  duration: Scalars['Float'];
  archiveId?: Maybe<Scalars['Int']>;
};

export type AsrInput = {
  language: Scalars['String'];
  text: Scalars['String'];
  url: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
};

export type Asr_Fr = {
  __typename?: 'ASR_fr';
  id: Scalars['Float'];
  url: Scalars['String'];
  text: Scalars['String'];
  source: Scalars['String'];
  sourceId: Scalars['Int'];
  duration: Scalars['Float'];
  archiveId: Scalars['Int'];
  createdAt: Scalars['String'];
};

export type Asr_Prod = {
  __typename?: 'ASR_prod';
  id: Scalars['Float'];
  language: Scalars['String'];
  url: Scalars['String'];
  text: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['String'];
};

export type Asr_Ttsadmin = {
  __typename?: 'ASR_ttsadmin';
  id: Scalars['Float'];
  language: Scalars['String'];
  url: Scalars['String'];
  text: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['String'];
};

export type AircallStatusOutput = {
  __typename?: 'AircallStatusOutput';
  aircallIntegration: Scalars['Boolean'];
  aircallToken?: Maybe<Scalars['String']>;
  webHookEndpoint?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type Annotation = {
  __typename?: 'Annotation';
  asr?: Maybe<Scalars['String']>;
  speakerType?: Maybe<Scalars['String']>;
  sentiment?: Maybe<Scalars['String']>;
};

export type Application = {
  __typename?: 'Application';
  id: Scalars['Float'];
  email: Scalars['String'];
  authorized: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Channels = {
  __typename?: 'Channels';
  id: Scalars['Float'];
  url: Scalars['String'];
  language: Scalars['String'];
  updatedAt: Scalars['String'];
  transcriptUpdatedAt: Scalars['String'];
  utterancesCount: Scalars['Int'];
  totalDuration: Scalars['Float'];
};

export type ContextsInput = {
  language: Scalars['String'];
  text: Scalars['String'];
  sourceId: Scalars['String'];
  context: Scalars['String'];
  step: Scalars['Float'];
  metadata?: Maybe<Scalars['JSON']>;
};

export type DatabaseIdentifiers = {
  __typename?: 'DatabaseIdentifiers';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type Dataset = {
  __typename?: 'Dataset';
  _id: Scalars['String'];
  turns?: Maybe<Array<DatasetTurn>>;
  sections?: Maybe<Array<Scalars['String']>>;
  summaries?: Maybe<Array<Summary>>;
};

export type DatasetTurn = {
  __typename?: 'DatasetTurn';
  id?: Maybe<Scalars['Int']>;
  speaker?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type Gatherings = {
  __typename?: 'Gatherings';
  id: Scalars['Float'];
  dataset: Scalars['String'];
  archiveId: Scalars['Int'];
  path: Scalars['String'];
  source: Scalars['String'];
  createdAt: Scalars['DateTime'];
  addedDuration: Scalars['Float'];
};

export type GuestLoginResponse = {
  __typename?: 'GuestLoginResponse';
  guestToken: Scalars['String'];
};

export type Integration = {
  __typename?: 'Integration';
  id: Scalars['Float'];
  type: Scalars['String'];
  token: Scalars['String'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Item = {
  __typename?: 'Item';
  type?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};


export type Meeting = {
  __typename?: 'Meeting';
  _id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  notionId?: Maybe<Scalars['String']>;
  reportGenerated?: Maybe<Scalars['Int']>;
  template?: Maybe<Scalars['String']>;
  isExternal?: Maybe<Scalars['Boolean']>;
  short_summary?: Maybe<Scalars['String']>;
  long_summary?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  creationTime?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['String']>;
  durationPlanned?: Maybe<Scalars['String']>;
  durationActual?: Maybe<Scalars['String']>;
  attendants?: Maybe<Array<Scalars['Int']>>;
  creator: Scalars['Int'];
  workspace?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  turns?: Maybe<Array<Turn>>;
  annotations?: Maybe<Annotation>;
};

export type MultiPartUpload = {
  __typename?: 'MultiPartUpload';
  records: Array<SignedRecords>;
  uploadId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  abortMultiplePartsUpload: Scalars['Boolean'];
  addASRFR: Scalars['Boolean'];
  addMockUpTurns: Meeting;
  adminSetWebHookEndpoint: Scalars['Boolean'];
  applyToBeta: Scalars['Boolean'];
  archivePage: Scalars['Boolean'];
  authorize: Scalars['Boolean'];
  bulkInsertVideos: Scalars['Boolean'];
  bulkUpdateChannelsByIds: Scalars['Boolean'];
  bulkUpdateChannelsTranscripts: Scalars['Boolean'];
  calls: Scalars['Boolean'];
  changePassword: UserResponse;
  cleanAllSections: Meeting;
  completeMultiplePartsUpload: Scalars['Boolean'];
  confirmUser?: Maybe<Scalars['Boolean']>;
  createAnnotatedData: Dataset;
  createAsrProdAnnotation: Scalars['Boolean'];
  createAsrTTSAdminAnnotation: Scalars['Boolean'];
  createChannel: Channels;
  createDatabase: Scalars['String'];
  createGathering: Gatherings;
  createMeeting: Meeting;
  createPage: Scalars['String'];
  createReport: Scalars['String'];
  createThematic: Template;
  deleteAllTurns: Meeting;
  deleteNotionToken: Scalars['Boolean'];
  deleteThematic: Scalars['Boolean'];
  fake_AddTurns: Meeting;
  fake_SetNotionId: Meeting;
  fake_SetWorkspaceId: Meeting;
  forgotPassword: Scalars['Boolean'];
  getGoogleCalendarAccess?: Maybe<Scalars['Boolean']>;
  go: Scalars['Boolean'];
  googleRegisterOrLogin: UserResponse;
  guestLogin: GuestLoginResponse;
  hasAuthorization: Scalars['Boolean'];
  insertContexts: Scalars['Boolean'];
  insertSections: Scalars['Boolean'];
  insertSentiments: Scalars['Boolean'];
  insertThematics: Scalars['Boolean'];
  lightTranscribe: Scalars['String'];
  login: UserResponse;
  logout: Scalars['Boolean'];
  notionIdTest: Scalars['String'];
  refreshAllSections: Meeting;
  register: UserResponse;
  revokeAuthorization: Scalars['Boolean'];
  setAircallToken: Scalars['Boolean'];
  setAnnotation: Scalars['Boolean'];
  setIsActive: Scalars['Boolean'];
  setNotionToken: Scalars['Boolean'];
  setNotionWorkspaceId: Scalars['Boolean'];
  setTurnMsg: Scalars['Boolean'];
  setWebhookEndpoint: Scalars['Boolean'];
  signS3: SignedUrlResponse;
  signedUploadToGCP: Scalars['String'];
  startMultiplePartsUpload: MultiPartUpload;
  testThematics: Scalars['Boolean'];
  test_queue: Meeting;
  transcribe: Scalars['String'];
  updateBlock: Scalars['Boolean'];
  updateMeeting: Meeting;
  updateNameIfNotionUser: Scalars['Boolean'];
  updatePage: Scalars['Boolean'];
  updateThematic: Scalars['Boolean'];
  updateTurnAnnotations: Scalars['Boolean'];
  updateTurnUrl: Scalars['Boolean'];
  updateVideoChannel: Scalars['Boolean'];
  uploadFile: Scalars['Boolean'];
  videoDownloadAcquireLock: Videos;
  videoDownloadFinishedReleaseLock: Scalars['Boolean'];
};


export type MutationAbortMultiplePartsUploadArgs = {
  uploadId: Scalars['String'];
  filename: Scalars['String'];
};


export type MutationAddAsrfrArgs = {
  data: Array<AsrfrInput>;
};


export type MutationAddMockUpTurnsArgs = {
  MeetingId: Scalars['String'];
};


export type MutationAdminSetWebHookEndpointArgs = {
  userId: Scalars['Float'];
  aircallToken: Scalars['String'];
};


export type MutationApplyToBetaArgs = {
  email: Scalars['String'];
};


export type MutationArchivePageArgs = {
  notionId: Scalars['String'];
};


export type MutationAuthorizeArgs = {
  email: Scalars['String'];
};


export type MutationBulkInsertVideosArgs = {
  data: Array<VideosInput>;
};


export type MutationBulkUpdateChannelsByIdsArgs = {
  ids: Array<Scalars['Int']>;
  dataset: Scalars['String'];
};


export type MutationBulkUpdateChannelsTranscriptsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationCallsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  userId: Scalars['Float'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCleanAllSectionsArgs = {
  MeetingId: Scalars['String'];
};


export type MutationCompleteMultiplePartsUploadArgs = {
  parts: Array<Part>;
  uploadId: Scalars['String'];
  filename: Scalars['String'];
};


export type MutationConfirmUserArgs = {
  token: Scalars['String'];
};


export type MutationCreateAnnotatedDataArgs = {
  summaries?: Maybe<Array<SummaryInput>>;
  sections?: Maybe<Array<Scalars['String']>>;
  turns?: Maybe<Array<TurnInput>>;
};


export type MutationCreateAsrProdAnnotationArgs = {
  data: Array<AsrInput>;
};


export type MutationCreateAsrTtsAdminAnnotationArgs = {
  data: Array<AsrInput>;
};


export type MutationCreateChannelArgs = {
  url: Scalars['String'];
  language: Scalars['String'];
};


export type MutationCreateDatabaseArgs = {
  id: Scalars['String'];
};


export type MutationCreateGatheringArgs = {
  archiveId: Scalars['Float'];
  addedDuration: Scalars['Float'];
  source: Scalars['String'];
  path: Scalars['String'];
  dataset: Scalars['String'];
};


export type MutationCreateMeetingArgs = {
  url: Scalars['String'];
  zone: Scalars['String'];
  type: Scalars['String'];
  attendants?: Maybe<Array<Scalars['Int']>>;
  durationPlanned: Scalars['String'];
  creationTime: Scalars['String'];
  time: Scalars['String'];
  date: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationCreatePageArgs = {
  title: Scalars['String'];
  idMeeting: Scalars['String'];
};


export type MutationCreateReportArgs = {
  meetingId: Scalars['String'];
  notionId: Scalars['String'];
};


export type MutationCreateThematicArgs = {
  color: Scalars['String'];
  template: Scalars['String'];
  thematic: Scalars['String'];
};


export type MutationDeleteAllTurnsArgs = {
  MeetingId: Scalars['String'];
};


export type MutationDeleteThematicArgs = {
  id: Scalars['Float'];
};


export type MutationFake_AddTurnsArgs = {
  MeetingId: Scalars['String'];
};


export type MutationFake_SetNotionIdArgs = {
  MeetingId: Scalars['String'];
};


export type MutationFake_SetWorkspaceIdArgs = {
  MeetingId: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGetGoogleCalendarAccessArgs = {
  accessToken: Scalars['String'];
};


export type MutationGoogleRegisterOrLoginArgs = {
  accessToken: Scalars['String'];
  token: Scalars['String'];
};


export type MutationHasAuthorizationArgs = {
  email: Scalars['String'];
};


export type MutationInsertContextsArgs = {
  data: Array<ContextsInput>;
};


export type MutationInsertSectionsArgs = {
  data: Array<SectionsInput>;
};


export type MutationInsertSentimentsArgs = {
  data: Array<SentimentsInput>;
};


export type MutationInsertThematicsArgs = {
  data: Array<ThematicsInput>;
};


export type MutationLightTranscribeArgs = {
  name: Scalars['String'];
  languageCode: Scalars['String'];
  url: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  usernameOrEmail: Scalars['String'];
};


export type MutationNotionIdTestArgs = {
  notionToken: Scalars['String'];
};


export type MutationRefreshAllSectionsArgs = {
  MeetingId: Scalars['String'];
};


export type MutationRegisterArgs = {
  options: UsernamePasswordInput;
};


export type MutationRevokeAuthorizationArgs = {
  email: Scalars['String'];
};


export type MutationSetAircallTokenArgs = {
  code: Scalars['String'];
};


export type MutationSetAnnotationArgs = {
  type: Scalars['String'];
  annotation: Scalars['String'];
  idMeeting: Scalars['String'];
};


export type MutationSetIsActiveArgs = {
  isActive: Scalars['Boolean'];
  id: Scalars['Float'];
};


export type MutationSetNotionTokenArgs = {
  code: Scalars['String'];
};


export type MutationSetNotionWorkspaceIdArgs = {
  notionWorkspaceType: Scalars['String'];
  notionWorkspaceId: Scalars['String'];
};


export type MutationSetTurnMsgArgs = {
  seqId: Scalars['Float'];
  MeetingId: Scalars['String'];
};


export type MutationSignS3Args = {
  filetype: Scalars['String'];
  filename: Scalars['String'];
};


export type MutationSignedUploadToGcpArgs = {
  fileName: Scalars['String'];
};


export type MutationStartMultiplePartsUploadArgs = {
  parts: Scalars['Float'];
  filename: Scalars['String'];
};


export type MutationTest_QueueArgs = {
  MeetingId: Scalars['String'];
};


export type MutationTranscribeArgs = {
  languageCode: Scalars['String'];
  thematics: Array<Scalars['String']>;
  filterSections: Scalars['Boolean'];
  seqId: Scalars['Float'];
  end: Scalars['Float'];
  start: Scalars['Float'];
  speaker: Scalars['String'];
  timestamp: Scalars['String'];
  meetingId: Scalars['String'];
  url: Scalars['String'];
};


export type MutationUpdateBlockArgs = {
  content: Scalars['String'];
  blockId: Scalars['String'];
};


export type MutationUpdateMeetingArgs = {
  long_summary?: Maybe<Scalars['String']>;
  short_summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  idMeeting: Scalars['String'];
};


export type MutationUpdatePageArgs = {
  isExternal: Scalars['Boolean'];
  properties: Array<PropertiesInput>;
  notionId: Scalars['String'];
};


export type MutationUpdateThematicArgs = {
  template: Scalars['String'];
  thematic: Scalars['String'];
  color: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationUpdateTurnAnnotationsArgs = {
  annotations: TurnAnnotationsInput;
  idTurn: Scalars['String'];
  idMeeting: Scalars['String'];
};


export type MutationUpdateTurnUrlArgs = {
  url: Scalars['String'];
  idTurn: Scalars['String'];
  idMeeting: Scalars['String'];
};


export type MutationUpdateVideoChannelArgs = {
  channel: Scalars['String'];
  url: Scalars['String'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationVideoDownloadFinishedReleaseLockArgs = {
  url: Scalars['String'];
};

export type NerAnnotationInput = {
  word?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type NerEntity = {
  __typename?: 'NEREntity';
  word?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type NotionDatabaseResponse = {
  __typename?: 'NotionDatabaseResponse';
  database?: Maybe<DatabaseIdentifiers>;
};

export type Part = {
  ETag: Scalars['String'];
  PartNumber: Scalars['Float'];
};

export type PropertiesInput = {
  property: Scalars['String'];
  content: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  aircallStatus: AircallStatusOutput;
  annotatedData: Array<Dataset>;
  application: Application;
  applications: Array<Application>;
  asr: Array<Asr_Fr>;
  asrFR: Array<Asr_Fr>;
  asrProd: Array<Asr_Prod>;
  asrProdbySource: Array<Asr_Prod>;
  asrTTS: Array<Asr_Ttsadmin>;
  channelsTranscriptUpdated: Array<Channels>;
  check: Scalars['String'];
  getBlocks: Scalars['Boolean'];
  getMemoryToken: UserResponse;
  getNotionDatabaseId?: Maybe<Scalars['String']>;
  getNotionDatabases: Array<NotionDatabaseResponse>;
  getNotionToken: Scalars['Boolean'];
  getOneDatabase: Scalars['Boolean'];
  getPage: Scalars['Boolean'];
  getTurn: Turn;
  getTurnMsg: Scalars['String'];
  hello: Scalars['String'];
  lastGathering: Gatherings;
  me?: Maybe<User>;
  meeting: Meeting;
  meetingByNotionId: Meeting;
  meetingByNotionIdAdmin: Meeting;
  meetingByUrl: Meeting;
  meetingReportExists: Scalars['Boolean'];
  meetingsByUser: Array<Meeting>;
  nextUnAnnotatedMeeting: Meeting;
  notionWorkspaces: Array<NotionDatabaseResponse>;
  requiresAnnotation: Meeting;
  requiresAnnotationASR: Meeting;
  routines: Scalars['String'];
  searchMeetings: Array<Meeting>;
  start: Scalars['String'];
  thematics: Array<Template>;
  uniqueChannels: Array<Scalars['String']>;
  userIntegrations: Array<Integration>;
  users?: Maybe<Array<UsersData>>;
  video: Videos;
  videoDoesntExists: Scalars['Boolean'];
  videosByChannel: Array<Videos>;
  videosByLanguage: Array<Videos>;
  videosByLanguageNoChannel: Array<Videos>;
};


export type QueryAircallStatusArgs = {
  userId: Scalars['Float'];
};


export type QueryApplicationArgs = {
  email: Scalars['String'];
};


export type QueryAsrFrArgs = {
  skip: Scalars['Float'];
  take: Scalars['Float'];
};


export type QueryAsrProdArgs = {
  skip: Scalars['Float'];
  take: Scalars['Float'];
  language: Scalars['String'];
};


export type QueryAsrProdbySourceArgs = {
  source: Scalars['String'];
};


export type QueryAsrTtsArgs = {
  skip: Scalars['Float'];
  take: Scalars['Float'];
  language: Scalars['String'];
};


export type QueryChannelsTranscriptUpdatedArgs = {
  language: Scalars['String'];
  take: Scalars['Float'];
};


export type QueryGetBlocksArgs = {
  blockId: Scalars['String'];
};


export type QueryGetTurnArgs = {
  idTurn: Scalars['String'];
  idMeeting: Scalars['String'];
};


export type QueryGetTurnMsgArgs = {
  seqId: Scalars['Float'];
  MeetingId: Scalars['String'];
};


export type QueryLastGatheringArgs = {
  dataset: Scalars['String'];
};


export type QueryMeetingArgs = {
  idMeeting: Scalars['String'];
};


export type QueryMeetingByNotionIdArgs = {
  notionId: Scalars['String'];
};


export type QueryMeetingByNotionIdAdminArgs = {
  notionId: Scalars['String'];
};


export type QueryMeetingByUrlArgs = {
  url: Scalars['String'];
};


export type QueryMeetingReportExistsArgs = {
  idMeeting: Scalars['String'];
};


export type QueryMeetingsByUserArgs = {
  id: Scalars['Float'];
};


export type QueryRequiresAnnotationArgs = {
  annotationType: Scalars['String'];
};


export type QuerySearchMeetingsArgs = {
  query: Scalars['String'];
};


export type QueryVideoArgs = {
  url: Scalars['String'];
};


export type QueryVideoDoesntExistsArgs = {
  url: Scalars['String'];
};


export type QueryVideosByChannelArgs = {
  channel: Scalars['String'];
  take: Scalars['Float'];
  skip: Scalars['Float'];
};


export type QueryVideosByLanguageArgs = {
  take: Scalars['Float'];
  skip: Scalars['Float'];
  language: Scalars['String'];
};


export type QueryVideosByLanguageNoChannelArgs = {
  take: Scalars['Float'];
  skip: Scalars['Float'];
  language: Scalars['String'];
};

export type Section = {
  __typename?: 'Section';
  section?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type SectionAnnotationInput = {
  section?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type SectionsInput = {
  language: Scalars['String'];
  text: Scalars['String'];
  sourceId: Scalars['String'];
  sections?: Maybe<Scalars['JSON']>;
  step: Scalars['Float'];
  metadata?: Maybe<Scalars['JSON']>;
};

export type SentimentsInput = {
  language: Scalars['String'];
  text: Scalars['String'];
  sourceId: Scalars['String'];
  sentiment: Scalars['String'];
  step: Scalars['Float'];
  metadata?: Maybe<Scalars['JSON']>;
};

export type SignedRecords = {
  __typename?: 'SignedRecords';
  signedRequest: Scalars['String'];
  id: Scalars['Float'];
};

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  signedRequest: Scalars['String'];
  url: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  subscribeToMeeting: Turn;
};


export type SubscriptionSubscribeToMeetingArgs = {
  topic: Scalars['String'];
};

export type Summary = {
  __typename?: 'Summary';
  section?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type SummaryInput = {
  section: Scalars['String'];
  summary: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['Float'];
  userId: Scalars['Float'];
  template: Scalars['String'];
  thematic: Scalars['String'];
  color: Scalars['String'];
};

export type ThematicsInput = {
  language: Scalars['String'];
  text: Scalars['String'];
  sourceId: Scalars['String'];
  thematic: Scalars['String'];
  step: Scalars['Float'];
  metadata?: Maybe<Scalars['JSON']>;
};

export type Topic = {
  __typename?: 'Topic';
  topic?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
};

export type TopicAnnotationInput = {
  topic?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
};

export type Turn = {
  __typename?: 'Turn';
  _id: Scalars['String'];
  speaker: Scalars['String'];
  text: Scalars['String'];
  timestamp: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  filterSections?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
  seqId?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
  topics?: Maybe<Array<Topic>>;
  section?: Maybe<Scalars['String']>;
  sentiment?: Maybe<Scalars['String']>;
  sections?: Maybe<Array<Section>>;
  items?: Maybe<Array<Item>>;
  summary?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Scalars['String']>>;
  entities?: Maybe<Array<NerEntity>>;
  annotations?: Maybe<TurnAnnotation>;
};

export type TurnAnnotation = {
  __typename?: 'TurnAnnotation';
  topics?: Maybe<Array<Topic>>;
  text?: Maybe<Scalars['String']>;
  sentiment?: Maybe<Scalars['String']>;
  sections?: Maybe<Array<Section>>;
  summary?: Maybe<Scalars['String']>;
  prompt?: Maybe<Scalars['String']>;
  questions?: Maybe<Scalars['String']>;
  entities?: Maybe<Array<NerEntity>>;
};

export type TurnAnnotationsInput = {
  topics?: Maybe<Array<TopicAnnotationInput>>;
  sentiment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Scalars['String']>>;
  sections?: Maybe<Array<SectionAnnotationInput>>;
  summary?: Maybe<Scalars['String']>;
  prompt?: Maybe<Scalars['String']>;
  entities?: Maybe<Array<NerAnnotationInput>>;
};

export type TurnInput = {
  id?: Maybe<Scalars['Int']>;
  speaker?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['Float'];
  username: Scalars['String'];
  email: Scalars['String'];
  userRights?: Maybe<Scalars['JSON']>;
  googleAccessToken: Scalars['String'];
  notionToken: Scalars['String'];
  notionWorkspace: Scalars['String'];
  notionWorskspaceIcon: Scalars['String'];
  notionBotId: Scalars['String'];
  notionWorkspaceId: Scalars['String'];
  notionParentWorkspaceId: Scalars['String'];
  notionWorkspaceType: Scalars['String'];
  integrations: Array<Integration>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<User>;
  token?: Maybe<Scalars['String']>;
};

export type UsernamePasswordInput = {
  email: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

export type UsersData = {
  __typename?: 'UsersData';
  id: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
};

export type Videos = {
  __typename?: 'Videos';
  id: Scalars['Float'];
  url: Scalars['String'];
  language: Scalars['String'];
  transcript?: Maybe<Scalars['JSON']>;
  downloaded: Scalars['Boolean'];
  processed: Scalars['Boolean'];
  updatedAt: Scalars['String'];
  lockedAt: Scalars['String'];
  channel: Scalars['String'];
};

export type VideosInput = {
  url: Scalars['String'];
  transcript?: Maybe<Scalars['JSON']>;
  downloaded?: Maybe<Scalars['Boolean']>;
  processed?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
};

export type AdminSetWebHookEndpointMutationVariables = Exact<{
  aircallToken: Scalars['String'];
  userId: Scalars['Float'];
}>;


export type AdminSetWebHookEndpointMutation = { __typename?: 'Mutation', adminSetWebHookEndpoint: boolean };

export type AuthorizeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type AuthorizeMutation = { __typename?: 'Mutation', authorize: boolean };

export type CallsMutationVariables = Exact<{
  userId: Scalars['Float'];
  token?: Maybe<Scalars['String']>;
  all?: Maybe<Scalars['Boolean']>;
}>;


export type CallsMutation = { __typename?: 'Mutation', calls: boolean };

export type CreateAsrProdAnnotationMutationVariables = Exact<{
  data: Array<AsrInput> | AsrInput;
}>;


export type CreateAsrProdAnnotationMutation = { __typename?: 'Mutation', createAsrProdAnnotation: boolean };

export type InsertContextsMutationVariables = Exact<{
  data: Array<ContextsInput> | ContextsInput;
}>;


export type InsertContextsMutation = { __typename?: 'Mutation', insertContexts: boolean };

export type InsertSectionsMutationVariables = Exact<{
  data: Array<SectionsInput> | SectionsInput;
}>;


export type InsertSectionsMutation = { __typename?: 'Mutation', insertSections: boolean };

export type InsertSentimentsMutationVariables = Exact<{
  data: Array<SentimentsInput> | SentimentsInput;
}>;


export type InsertSentimentsMutation = { __typename?: 'Mutation', insertSentiments: boolean };

export type InsertThematicsMutationVariables = Exact<{
  data: Array<ThematicsInput> | ThematicsInput;
}>;


export type InsertThematicsMutation = { __typename?: 'Mutation', insertThematics: boolean };

export type LoginMutationVariables = Exact<{
  usernameOrEmail: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'UserResponse', errors?: Maybe<Array<{ __typename?: 'FieldError', field: string, message: string }>>, user?: Maybe<{ __typename?: 'User', id: number, username: string, email: string, userRights?: Maybe<any> }> } };

export type SetAnnotationMutationVariables = Exact<{
  idMeeting: Scalars['String'];
  annotation: Scalars['String'];
  type: Scalars['String'];
}>;


export type SetAnnotationMutation = { __typename?: 'Mutation', setAnnotation: boolean };

export type CreateAsrTtsAdminAnnotationMutationVariables = Exact<{
  data: Array<AsrInput> | AsrInput;
}>;


export type CreateAsrTtsAdminAnnotationMutation = { __typename?: 'Mutation', createAsrTTSAdminAnnotation: boolean };

export type SignedUploadToGcpMutationVariables = Exact<{
  fileName: Scalars['String'];
}>;


export type SignedUploadToGcpMutation = { __typename?: 'Mutation', signedUploadToGCP: string };

export type AircallStatusQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;


export type AircallStatusQuery = { __typename?: 'Query', aircallStatus: { __typename?: 'AircallStatusOutput', aircallIntegration: boolean, username?: Maybe<string>, aircallToken?: Maybe<string>, webHookEndpoint?: Maybe<string> } };

export type ApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicationsQuery = { __typename?: 'Query', applications: Array<{ __typename?: 'Application', id: number, email: string, authorized: boolean, createdAt: string }> };

export type AsrProdbySourceQueryVariables = Exact<{
  source: Scalars['String'];
}>;


export type AsrProdbySourceQuery = { __typename?: 'Query', asrProdbySource: Array<{ __typename?: 'ASR_prod', text: string, id: number }> };

export type HelloQueryVariables = Exact<{ [key: string]: never; }>;


export type HelloQuery = { __typename?: 'Query', hello: string };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: Maybe<{ __typename?: 'User', id: number, username: string, email: string, userRights?: Maybe<any> }> };

export type MeetingByNotionIdAdminQueryVariables = Exact<{
  notionId: Scalars['String'];
}>;


export type MeetingByNotionIdAdminQuery = { __typename?: 'Query', meetingByNotionIdAdmin: { __typename?: 'Meeting', _id: string, title?: Maybe<string>, notionId?: Maybe<string>, url?: Maybe<string>, short_summary?: Maybe<string>, long_summary?: Maybe<string>, summary?: Maybe<string>, template?: Maybe<string>, turns?: Maybe<Array<{ __typename?: 'Turn', speaker: string, seqId?: Maybe<number>, timestamp: string, text: string, section?: Maybe<string>, sentiment?: Maybe<string>, summary?: Maybe<string>, topics?: Maybe<Array<{ __typename?: 'Topic', topic?: Maybe<string>, score?: Maybe<number> }>> }>> } };

export type MeetingsByUserQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type MeetingsByUserQuery = { __typename?: 'Query', meetingsByUser: Array<{ __typename?: 'Meeting', title?: Maybe<string>, date?: Maybe<string>, long_summary?: Maybe<string>, _id: string, notionId?: Maybe<string> }> };

export type RequiresAnnotationQueryVariables = Exact<{
  annotationType: Scalars['String'];
}>;


export type RequiresAnnotationQuery = { __typename?: 'Query', requiresAnnotation: { __typename?: 'Meeting', _id: string, turns?: Maybe<Array<{ __typename?: 'Turn', _id: string, speaker: string, text: string, url?: Maybe<string>, seqId?: Maybe<number>, start?: Maybe<number>, end?: Maybe<number>, timestamp: string, signedUrl?: Maybe<string> }>> } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users?: Maybe<Array<{ __typename?: 'UsersData', id: number, username?: Maybe<string> }>> };


export const AdminSetWebHookEndpointDocument = gql`
    mutation AdminSetWebHookEndpoint($aircallToken: String!, $userId: Float!) {
  adminSetWebHookEndpoint(aircallToken: $aircallToken, userId: $userId)
}
    `;
export type AdminSetWebHookEndpointMutationFn = Apollo.MutationFunction<AdminSetWebHookEndpointMutation, AdminSetWebHookEndpointMutationVariables>;

/**
 * __useAdminSetWebHookEndpointMutation__
 *
 * To run a mutation, you first call `useAdminSetWebHookEndpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSetWebHookEndpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSetWebHookEndpointMutation, { data, loading, error }] = useAdminSetWebHookEndpointMutation({
 *   variables: {
 *      aircallToken: // value for 'aircallToken'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminSetWebHookEndpointMutation(baseOptions?: Apollo.MutationHookOptions<AdminSetWebHookEndpointMutation, AdminSetWebHookEndpointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminSetWebHookEndpointMutation, AdminSetWebHookEndpointMutationVariables>(AdminSetWebHookEndpointDocument, options);
      }
export type AdminSetWebHookEndpointMutationHookResult = ReturnType<typeof useAdminSetWebHookEndpointMutation>;
export type AdminSetWebHookEndpointMutationResult = Apollo.MutationResult<AdminSetWebHookEndpointMutation>;
export type AdminSetWebHookEndpointMutationOptions = Apollo.BaseMutationOptions<AdminSetWebHookEndpointMutation, AdminSetWebHookEndpointMutationVariables>;
export const AuthorizeDocument = gql`
    mutation Authorize($email: String!) {
  authorize(email: $email)
}
    `;
export type AuthorizeMutationFn = Apollo.MutationFunction<AuthorizeMutation, AuthorizeMutationVariables>;

/**
 * __useAuthorizeMutation__
 *
 * To run a mutation, you first call `useAuthorizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeMutation, { data, loading, error }] = useAuthorizeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAuthorizeMutation(baseOptions?: Apollo.MutationHookOptions<AuthorizeMutation, AuthorizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorizeMutation, AuthorizeMutationVariables>(AuthorizeDocument, options);
      }
export type AuthorizeMutationHookResult = ReturnType<typeof useAuthorizeMutation>;
export type AuthorizeMutationResult = Apollo.MutationResult<AuthorizeMutation>;
export type AuthorizeMutationOptions = Apollo.BaseMutationOptions<AuthorizeMutation, AuthorizeMutationVariables>;
export const CallsDocument = gql`
    mutation Calls($userId: Float!, $token: String, $all: Boolean) {
  calls(userId: $userId, token: $token, all: $all)
}
    `;
export type CallsMutationFn = Apollo.MutationFunction<CallsMutation, CallsMutationVariables>;

/**
 * __useCallsMutation__
 *
 * To run a mutation, you first call `useCallsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callsMutation, { data, loading, error }] = useCallsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *      all: // value for 'all'
 *   },
 * });
 */
export function useCallsMutation(baseOptions?: Apollo.MutationHookOptions<CallsMutation, CallsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallsMutation, CallsMutationVariables>(CallsDocument, options);
      }
export type CallsMutationHookResult = ReturnType<typeof useCallsMutation>;
export type CallsMutationResult = Apollo.MutationResult<CallsMutation>;
export type CallsMutationOptions = Apollo.BaseMutationOptions<CallsMutation, CallsMutationVariables>;
export const CreateAsrProdAnnotationDocument = gql`
    mutation CreateAsrProdAnnotation($data: [ASRInput!]!) {
  createAsrProdAnnotation(data: $data)
}
    `;
export type CreateAsrProdAnnotationMutationFn = Apollo.MutationFunction<CreateAsrProdAnnotationMutation, CreateAsrProdAnnotationMutationVariables>;

/**
 * __useCreateAsrProdAnnotationMutation__
 *
 * To run a mutation, you first call `useCreateAsrProdAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAsrProdAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAsrProdAnnotationMutation, { data, loading, error }] = useCreateAsrProdAnnotationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAsrProdAnnotationMutation(baseOptions?: Apollo.MutationHookOptions<CreateAsrProdAnnotationMutation, CreateAsrProdAnnotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAsrProdAnnotationMutation, CreateAsrProdAnnotationMutationVariables>(CreateAsrProdAnnotationDocument, options);
      }
export type CreateAsrProdAnnotationMutationHookResult = ReturnType<typeof useCreateAsrProdAnnotationMutation>;
export type CreateAsrProdAnnotationMutationResult = Apollo.MutationResult<CreateAsrProdAnnotationMutation>;
export type CreateAsrProdAnnotationMutationOptions = Apollo.BaseMutationOptions<CreateAsrProdAnnotationMutation, CreateAsrProdAnnotationMutationVariables>;
export const InsertContextsDocument = gql`
    mutation insertContexts($data: [ContextsInput!]!) {
  insertContexts(data: $data)
}
    `;
export type InsertContextsMutationFn = Apollo.MutationFunction<InsertContextsMutation, InsertContextsMutationVariables>;

/**
 * __useInsertContextsMutation__
 *
 * To run a mutation, you first call `useInsertContextsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContextsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContextsMutation, { data, loading, error }] = useInsertContextsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertContextsMutation(baseOptions?: Apollo.MutationHookOptions<InsertContextsMutation, InsertContextsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertContextsMutation, InsertContextsMutationVariables>(InsertContextsDocument, options);
      }
export type InsertContextsMutationHookResult = ReturnType<typeof useInsertContextsMutation>;
export type InsertContextsMutationResult = Apollo.MutationResult<InsertContextsMutation>;
export type InsertContextsMutationOptions = Apollo.BaseMutationOptions<InsertContextsMutation, InsertContextsMutationVariables>;
export const InsertSectionsDocument = gql`
    mutation insertSections($data: [SectionsInput!]!) {
  insertSections(data: $data)
}
    `;
export type InsertSectionsMutationFn = Apollo.MutationFunction<InsertSectionsMutation, InsertSectionsMutationVariables>;

/**
 * __useInsertSectionsMutation__
 *
 * To run a mutation, you first call `useInsertSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSectionsMutation, { data, loading, error }] = useInsertSectionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertSectionsMutation(baseOptions?: Apollo.MutationHookOptions<InsertSectionsMutation, InsertSectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSectionsMutation, InsertSectionsMutationVariables>(InsertSectionsDocument, options);
      }
export type InsertSectionsMutationHookResult = ReturnType<typeof useInsertSectionsMutation>;
export type InsertSectionsMutationResult = Apollo.MutationResult<InsertSectionsMutation>;
export type InsertSectionsMutationOptions = Apollo.BaseMutationOptions<InsertSectionsMutation, InsertSectionsMutationVariables>;
export const InsertSentimentsDocument = gql`
    mutation insertSentiments($data: [SentimentsInput!]!) {
  insertSentiments(data: $data)
}
    `;
export type InsertSentimentsMutationFn = Apollo.MutationFunction<InsertSentimentsMutation, InsertSentimentsMutationVariables>;

/**
 * __useInsertSentimentsMutation__
 *
 * To run a mutation, you first call `useInsertSentimentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSentimentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSentimentsMutation, { data, loading, error }] = useInsertSentimentsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertSentimentsMutation(baseOptions?: Apollo.MutationHookOptions<InsertSentimentsMutation, InsertSentimentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSentimentsMutation, InsertSentimentsMutationVariables>(InsertSentimentsDocument, options);
      }
export type InsertSentimentsMutationHookResult = ReturnType<typeof useInsertSentimentsMutation>;
export type InsertSentimentsMutationResult = Apollo.MutationResult<InsertSentimentsMutation>;
export type InsertSentimentsMutationOptions = Apollo.BaseMutationOptions<InsertSentimentsMutation, InsertSentimentsMutationVariables>;
export const InsertThematicsDocument = gql`
    mutation insertThematics($data: [ThematicsInput!]!) {
  insertThematics(data: $data)
}
    `;
export type InsertThematicsMutationFn = Apollo.MutationFunction<InsertThematicsMutation, InsertThematicsMutationVariables>;

/**
 * __useInsertThematicsMutation__
 *
 * To run a mutation, you first call `useInsertThematicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertThematicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertThematicsMutation, { data, loading, error }] = useInsertThematicsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertThematicsMutation(baseOptions?: Apollo.MutationHookOptions<InsertThematicsMutation, InsertThematicsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertThematicsMutation, InsertThematicsMutationVariables>(InsertThematicsDocument, options);
      }
export type InsertThematicsMutationHookResult = ReturnType<typeof useInsertThematicsMutation>;
export type InsertThematicsMutationResult = Apollo.MutationResult<InsertThematicsMutation>;
export type InsertThematicsMutationOptions = Apollo.BaseMutationOptions<InsertThematicsMutation, InsertThematicsMutationVariables>;
export const LoginDocument = gql`
    mutation Login($usernameOrEmail: String!, $password: String!) {
  login(usernameOrEmail: $usernameOrEmail, password: $password) {
    errors {
      field
      message
    }
    user {
      id
      username
      email
      userRights
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      usernameOrEmail: // value for 'usernameOrEmail'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SetAnnotationDocument = gql`
    mutation SetAnnotation($idMeeting: String!, $annotation: String!, $type: String!) {
  setAnnotation(idMeeting: $idMeeting, annotation: $annotation, type: $type)
}
    `;
export type SetAnnotationMutationFn = Apollo.MutationFunction<SetAnnotationMutation, SetAnnotationMutationVariables>;

/**
 * __useSetAnnotationMutation__
 *
 * To run a mutation, you first call `useSetAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAnnotationMutation, { data, loading, error }] = useSetAnnotationMutation({
 *   variables: {
 *      idMeeting: // value for 'idMeeting'
 *      annotation: // value for 'annotation'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSetAnnotationMutation(baseOptions?: Apollo.MutationHookOptions<SetAnnotationMutation, SetAnnotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAnnotationMutation, SetAnnotationMutationVariables>(SetAnnotationDocument, options);
      }
export type SetAnnotationMutationHookResult = ReturnType<typeof useSetAnnotationMutation>;
export type SetAnnotationMutationResult = Apollo.MutationResult<SetAnnotationMutation>;
export type SetAnnotationMutationOptions = Apollo.BaseMutationOptions<SetAnnotationMutation, SetAnnotationMutationVariables>;
export const CreateAsrTtsAdminAnnotationDocument = gql`
    mutation createAsrTTSAdminAnnotation($data: [ASRInput!]!) {
  createAsrTTSAdminAnnotation(data: $data)
}
    `;
export type CreateAsrTtsAdminAnnotationMutationFn = Apollo.MutationFunction<CreateAsrTtsAdminAnnotationMutation, CreateAsrTtsAdminAnnotationMutationVariables>;

/**
 * __useCreateAsrTtsAdminAnnotationMutation__
 *
 * To run a mutation, you first call `useCreateAsrTtsAdminAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAsrTtsAdminAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAsrTtsAdminAnnotationMutation, { data, loading, error }] = useCreateAsrTtsAdminAnnotationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAsrTtsAdminAnnotationMutation(baseOptions?: Apollo.MutationHookOptions<CreateAsrTtsAdminAnnotationMutation, CreateAsrTtsAdminAnnotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAsrTtsAdminAnnotationMutation, CreateAsrTtsAdminAnnotationMutationVariables>(CreateAsrTtsAdminAnnotationDocument, options);
      }
export type CreateAsrTtsAdminAnnotationMutationHookResult = ReturnType<typeof useCreateAsrTtsAdminAnnotationMutation>;
export type CreateAsrTtsAdminAnnotationMutationResult = Apollo.MutationResult<CreateAsrTtsAdminAnnotationMutation>;
export type CreateAsrTtsAdminAnnotationMutationOptions = Apollo.BaseMutationOptions<CreateAsrTtsAdminAnnotationMutation, CreateAsrTtsAdminAnnotationMutationVariables>;
export const SignedUploadToGcpDocument = gql`
    mutation SignedUploadToGCP($fileName: String!) {
  signedUploadToGCP(fileName: $fileName)
}
    `;
export type SignedUploadToGcpMutationFn = Apollo.MutationFunction<SignedUploadToGcpMutation, SignedUploadToGcpMutationVariables>;

/**
 * __useSignedUploadToGcpMutation__
 *
 * To run a mutation, you first call `useSignedUploadToGcpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignedUploadToGcpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signedUploadToGcpMutation, { data, loading, error }] = useSignedUploadToGcpMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useSignedUploadToGcpMutation(baseOptions?: Apollo.MutationHookOptions<SignedUploadToGcpMutation, SignedUploadToGcpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignedUploadToGcpMutation, SignedUploadToGcpMutationVariables>(SignedUploadToGcpDocument, options);
      }
export type SignedUploadToGcpMutationHookResult = ReturnType<typeof useSignedUploadToGcpMutation>;
export type SignedUploadToGcpMutationResult = Apollo.MutationResult<SignedUploadToGcpMutation>;
export type SignedUploadToGcpMutationOptions = Apollo.BaseMutationOptions<SignedUploadToGcpMutation, SignedUploadToGcpMutationVariables>;
export const AircallStatusDocument = gql`
    query AircallStatus($userId: Float!) {
  aircallStatus(userId: $userId) {
    aircallIntegration
    username
    aircallToken
    webHookEndpoint
  }
}
    `;

/**
 * __useAircallStatusQuery__
 *
 * To run a query within a React component, call `useAircallStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAircallStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAircallStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAircallStatusQuery(baseOptions: Apollo.QueryHookOptions<AircallStatusQuery, AircallStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AircallStatusQuery, AircallStatusQueryVariables>(AircallStatusDocument, options);
      }
export function useAircallStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AircallStatusQuery, AircallStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AircallStatusQuery, AircallStatusQueryVariables>(AircallStatusDocument, options);
        }
export type AircallStatusQueryHookResult = ReturnType<typeof useAircallStatusQuery>;
export type AircallStatusLazyQueryHookResult = ReturnType<typeof useAircallStatusLazyQuery>;
export type AircallStatusQueryResult = Apollo.QueryResult<AircallStatusQuery, AircallStatusQueryVariables>;
export const ApplicationsDocument = gql`
    query Applications {
  applications {
    id
    email
    authorized
    createdAt
  }
}
    `;

/**
 * __useApplicationsQuery__
 *
 * To run a query within a React component, call `useApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, options);
      }
export function useApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, options);
        }
export type ApplicationsQueryHookResult = ReturnType<typeof useApplicationsQuery>;
export type ApplicationsLazyQueryHookResult = ReturnType<typeof useApplicationsLazyQuery>;
export type ApplicationsQueryResult = Apollo.QueryResult<ApplicationsQuery, ApplicationsQueryVariables>;
export const AsrProdbySourceDocument = gql`
    query AsrProdbySource($source: String!) {
  asrProdbySource(source: $source) {
    text
    id
  }
}
    `;

/**
 * __useAsrProdbySourceQuery__
 *
 * To run a query within a React component, call `useAsrProdbySourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAsrProdbySourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAsrProdbySourceQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useAsrProdbySourceQuery(baseOptions: Apollo.QueryHookOptions<AsrProdbySourceQuery, AsrProdbySourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AsrProdbySourceQuery, AsrProdbySourceQueryVariables>(AsrProdbySourceDocument, options);
      }
export function useAsrProdbySourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AsrProdbySourceQuery, AsrProdbySourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AsrProdbySourceQuery, AsrProdbySourceQueryVariables>(AsrProdbySourceDocument, options);
        }
export type AsrProdbySourceQueryHookResult = ReturnType<typeof useAsrProdbySourceQuery>;
export type AsrProdbySourceLazyQueryHookResult = ReturnType<typeof useAsrProdbySourceLazyQuery>;
export type AsrProdbySourceQueryResult = Apollo.QueryResult<AsrProdbySourceQuery, AsrProdbySourceQueryVariables>;
export const HelloDocument = gql`
    query Hello {
  hello
}
    `;

/**
 * __useHelloQuery__
 *
 * To run a query within a React component, call `useHelloQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelloQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelloQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelloQuery(baseOptions?: Apollo.QueryHookOptions<HelloQuery, HelloQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelloQuery, HelloQueryVariables>(HelloDocument, options);
      }
export function useHelloLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelloQuery, HelloQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelloQuery, HelloQueryVariables>(HelloDocument, options);
        }
export type HelloQueryHookResult = ReturnType<typeof useHelloQuery>;
export type HelloLazyQueryHookResult = ReturnType<typeof useHelloLazyQuery>;
export type HelloQueryResult = Apollo.QueryResult<HelloQuery, HelloQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    username
    email
    userRights
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MeetingByNotionIdAdminDocument = gql`
    query MeetingByNotionIdAdmin($notionId: String!) {
  meetingByNotionIdAdmin(notionId: $notionId) {
    _id
    title
    notionId
    url
    short_summary
    long_summary
    summary
    template
    turns {
      speaker
      seqId
      timestamp
      text
      topics {
        topic
        score
      }
      section
      sentiment
      summary
    }
  }
}
    `;

/**
 * __useMeetingByNotionIdAdminQuery__
 *
 * To run a query within a React component, call `useMeetingByNotionIdAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingByNotionIdAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingByNotionIdAdminQuery({
 *   variables: {
 *      notionId: // value for 'notionId'
 *   },
 * });
 */
export function useMeetingByNotionIdAdminQuery(baseOptions: Apollo.QueryHookOptions<MeetingByNotionIdAdminQuery, MeetingByNotionIdAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeetingByNotionIdAdminQuery, MeetingByNotionIdAdminQueryVariables>(MeetingByNotionIdAdminDocument, options);
      }
export function useMeetingByNotionIdAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeetingByNotionIdAdminQuery, MeetingByNotionIdAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeetingByNotionIdAdminQuery, MeetingByNotionIdAdminQueryVariables>(MeetingByNotionIdAdminDocument, options);
        }
export type MeetingByNotionIdAdminQueryHookResult = ReturnType<typeof useMeetingByNotionIdAdminQuery>;
export type MeetingByNotionIdAdminLazyQueryHookResult = ReturnType<typeof useMeetingByNotionIdAdminLazyQuery>;
export type MeetingByNotionIdAdminQueryResult = Apollo.QueryResult<MeetingByNotionIdAdminQuery, MeetingByNotionIdAdminQueryVariables>;
export const MeetingsByUserDocument = gql`
    query MeetingsByUser($id: Float!) {
  meetingsByUser(id: $id) {
    title
    date
    long_summary
    _id
    notionId
  }
}
    `;

/**
 * __useMeetingsByUserQuery__
 *
 * To run a query within a React component, call `useMeetingsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingsByUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMeetingsByUserQuery(baseOptions: Apollo.QueryHookOptions<MeetingsByUserQuery, MeetingsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeetingsByUserQuery, MeetingsByUserQueryVariables>(MeetingsByUserDocument, options);
      }
export function useMeetingsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeetingsByUserQuery, MeetingsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeetingsByUserQuery, MeetingsByUserQueryVariables>(MeetingsByUserDocument, options);
        }
export type MeetingsByUserQueryHookResult = ReturnType<typeof useMeetingsByUserQuery>;
export type MeetingsByUserLazyQueryHookResult = ReturnType<typeof useMeetingsByUserLazyQuery>;
export type MeetingsByUserQueryResult = Apollo.QueryResult<MeetingsByUserQuery, MeetingsByUserQueryVariables>;
export const RequiresAnnotationDocument = gql`
    query requiresAnnotation($annotationType: String!) {
  requiresAnnotation(annotationType: $annotationType) {
    _id
    turns {
      _id
      speaker
      text
      url
      seqId
      start
      end
      timestamp
      signedUrl
    }
  }
}
    `;

/**
 * __useRequiresAnnotationQuery__
 *
 * To run a query within a React component, call `useRequiresAnnotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequiresAnnotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequiresAnnotationQuery({
 *   variables: {
 *      annotationType: // value for 'annotationType'
 *   },
 * });
 */
export function useRequiresAnnotationQuery(baseOptions: Apollo.QueryHookOptions<RequiresAnnotationQuery, RequiresAnnotationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequiresAnnotationQuery, RequiresAnnotationQueryVariables>(RequiresAnnotationDocument, options);
      }
export function useRequiresAnnotationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequiresAnnotationQuery, RequiresAnnotationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequiresAnnotationQuery, RequiresAnnotationQueryVariables>(RequiresAnnotationDocument, options);
        }
export type RequiresAnnotationQueryHookResult = ReturnType<typeof useRequiresAnnotationQuery>;
export type RequiresAnnotationLazyQueryHookResult = ReturnType<typeof useRequiresAnnotationLazyQuery>;
export type RequiresAnnotationQueryResult = Apollo.QueryResult<RequiresAnnotationQuery, RequiresAnnotationQueryVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    id
    username
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;