import React from "react";
import styled from "styled-components";
import { useUsersQuery } from "../../generated/graphql";
import User from "./Users/User";

type UsersProps = {};

const Container = styled.div`
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 93vh;
  overflow-y: scroll;
`;

const Users: React.FC<UsersProps> = () => {
  const { data, error } = useUsersQuery();
  console.log("error:", error);
  return (
    <Container>
      {data?.users &&
        [...data.users]
          ?.sort((a, b) => a.id - b.id)
          .map((user) => <User id={user.id} username={user.username!} />)}
    </Container>
  );
};

export default Users;
