import React from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { useMeetingByNotionIdAdminQuery } from "../../../generated/graphql";

type MeetingByNotionIdProps = {};

const Container = styled.div`
  margin-left: 150px;
  margin-top: 150px;
  width: 100%;
`;
const TurnContainer = styled.div`
  grid-row: 1/3;
  grid-column: 1/4;
  display: grid;
  grid-template-columns: 200px 100px auto;
  grid-template-rows: 25px auto;
`;

const Time = styled.div`
  grid-row: 1;
  grid-column: 2;
  color: #9b9b9b;
  user-select: none;
`;

const Speaker = styled.div`
  grid-row: 1;
  grid-column: 1;
  user-select: none;
  color: #5c5050;
`;
const Text = styled.div`
  grid-row: 2/4;
  grid-column: 1/4;
  margin-bottom: 30px;
`;
const MeetingByNotionId: React.FC<MeetingByNotionIdProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useMeetingByNotionIdAdminQuery({
    variables: { notionId: id },
  });

  return (
    <Container>
      {data?.meetingByNotionIdAdmin.turns?.map((turn) => (
        <TurnContainer>
          <Speaker>{turn.speaker}</Speaker>
          <Time>{turn.timestamp}</Time>

          <Text>{turn.text}</Text>
        </TurnContainer>
      ))}
    </Container>
  );
};

export default MeetingByNotionId;
