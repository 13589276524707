import React from "react";
import { useApplicationsQuery } from "../../generated/graphql";
import Table from "./Application/Table";

type ApplicationsProps = {};

const Applications: React.FC<ApplicationsProps> = () => {
  const applications = useApplicationsQuery();
  return (
    <>
      {applications.data?.applications && (
        <Table applications={applications.data} />
      )}
    </>
  );
};

export default Applications;
