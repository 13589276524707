import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

type UserProps = { id: number; username: string };

const Container = styled.div`
  min-height: 75px;
  width: 90%;
  border: 1px solid #f1f1f1;
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const ID = styled.div`
  margin-left: 8px;
  margin-right: 4px;
  font-size: 12px;
  color: #696969;
  letter-spacing: 1px;
`;
const MeetingsButton = styled.div`
  width: 90px;
  height: 50%;
  background: #0c1f2b;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: lighter;
  border-radius: 8px;
  font-size: 14px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
`;

const GatherAircallMeetings = styled.div`
  width: 90px;
  height: 50%;
  color: #0c1f2b;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #0c1f2b;
  font-weight: lighter;
  border-radius: 8px;
  font-size: 14px;
  margin-left: auto;
  margin-right: 20px;
  text-align: center;
  cursor: pointer;
`;
const Username = styled.div`
  margin-left: 12px;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
`;

const User: React.FC<UserProps> = ({ id, username }) => {
  const history = useHistory();
  return (
    <Container>
      <ID>{id} </ID>
      <Username>{username}</Username>
      <GatherAircallMeetings onClick={() => history.push(`/aircall/${id}`)}>
        AIRCALL
      </GatherAircallMeetings>
      <MeetingsButton onClick={() => history.push(`/usermeetings/${id}`)}>
        MEETINGS
      </MeetingsButton>
    </Container>
  );
};

export default User;
