import React, { useState } from "react";

import {
  Audio,
  BlackButton,
  Buttons,
  Container,
  Header,
  Main,
  Separator,
  Speaker,
  Text,
  Transcription,
  Turn,
  WhiteButton,
} from "../../../components/NLPAnnotations";

import { useAnnotation } from "../../../utils/useAnnotations";
import TurnContext from "./Context/TurnContext";
import { useInsertContextsMutation } from "../../../generated/graphql";

type Annotation = {
  step: number;
  context: string;
  metadata?: any;
  text: string;
  asrAnnotation: string;
  sourceId: string;
  language: string;
};

const Context: React.FC = () => {
  const [currentCtx, setCurrentCtx] = useState("");
  const {
    setAnnotated,
    language,
    setLanguage,
    step,
    setStep,
    stepRef,
    data,
    refetch,
    asrAnnotations,
  } = useAnnotation("context");

  const [contextAnnotations, setContextAnnotations] = useState<Annotation[]>(
    []
  );
  const [insertContexts] = useInsertContextsMutation();
  console.log(contextAnnotations);
  return (
    <Container>
      <Header>Context Annotation</Header>
      <Main
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            setContextAnnotations((ctxAnnotations) => {
              const annotatedTurn = ctxAnnotations.findIndex(
                (e) => e.step === stepRef.current
              );
              if (annotatedTurn > -1) {
                ctxAnnotations[annotatedTurn].context = currentCtx;
                return [...ctxAnnotations];
              } else if (
                data &&
                data.requiresAnnotation.turns &&
                annotatedTurn > data?.requiresAnnotation.turns?.length
              ) {
                return ctxAnnotations;
              } else {
                const newAnnotatedTurn: Annotation = {
                  step: step,
                  context: currentCtx,
                  asrAnnotation:
                    asrAnnotations.data?.asrProdbySource[stepRef.current]
                      ?.text || "",
                  language,
                  sourceId: data?.requiresAnnotation._id!,
                  text:
                    data?.requiresAnnotation.turns![stepRef.current].text || "",
                };
                return [...ctxAnnotations, newAnnotatedTurn];
              }
            });
            if (data && data.requiresAnnotation.turns)
              if (step < data?.requiresAnnotation.turns.length - 1) {
                setStep((step) => step + 1);
                stepRef.current = stepRef.current + 1;
                //setCurrentCtx("");
              }
          }
        }}>
        {data?.requiresAnnotation.turns?.map((turn, id) => (
          <Turn direction="column" key={turn._id} focused={id === step}>
            <Speaker>{turn.speaker}</Speaker>
            <Text>
              <Transcription>{turn.text}</Transcription>
              <Separator />
              <Transcription>
                {asrAnnotations.data?.asrProdbySource[id]
                  ? asrAnnotations.data?.asrProdbySource[id].text
                  : "No Annotations"}
              </Transcription>
              <Separator />
              <Audio
                controls
                src={!!turn.signedUrl ? turn.signedUrl! : turn.url!}
                controlsList="nodownload"
              />
            </Text>
            <TurnContext
              contextAnnotations={contextAnnotations}
              id={id}
              step={step}
              currentCtx={currentCtx}
              setCurrentCtx={setCurrentCtx}
              key={id}
            />
          </Turn>
        ))}
      </Main>
      <Buttons>
        <WhiteButton
          onClick={() => {
            setLanguage((language) => (language === "FR" ? "ENG" : "FR"));
          }}>
          {language}
        </WhiteButton>
        <BlackButton
          onClick={async () => {
            if (
              contextAnnotations.length !==
              data?.requiresAnnotation.turns?.length
            ) {
              alert("Annotate everything first please");
            } else {
              const contextInput = contextAnnotations.map((turn) => ({
                language,
                text: turn.text,
                context: turn.context,
                step: turn.step,
                sourceId: turn.sourceId,
                metadata: { asrAnnotation: turn.asrAnnotation },
              }));

              console.log(contextInput);
              await insertContexts({
                variables: { data: contextInput },
                context: { clientName: "datasets" },
              });

              await setAnnotated({
                variables: {
                  idMeeting: data.requiresAnnotation._id,
                  annotation: "annotated",
                  type: "context",
                },
              });
              await refetch();
            }
          }}>
          SAVE
        </BlackButton>
        <BlackButton
          onClick={async () => {
            setContextAnnotations([]);
            setCurrentCtx("");
            setStep(0);
            stepRef.current = 0;
            await refetch();
          }}
          style={{ marginTop: "auto", marginBottom: "10px" }}>
          CANCEL
        </BlackButton>
      </Buttons>
    </Container>
  );
};

export default Context;
