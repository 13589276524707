import React, { useMemo } from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import {
  ApplicationsDocument,
  ApplicationsQuery,
  useAuthorizeMutation,
} from "../../../generated/graphql";

type TableProps = {
  applications: ApplicationsQuery;
};

const Container = styled.div`
  grid-column: 2;
  grid-row: 2;
  display: flex;
  font-family: "Raleway", sans-serif;
  align-items: flex-start;
  justify-content: center;
`;

const TableContainer = styled.table`
  width: 90%;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
`;
const TableHead = styled.th`
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  color: #22313a;
`;
const TableRow = styled.tr`
  &:hover {
    background: #f1f1f1;
  }
`;
const TableCell = styled.td`
  border-top: 1px solid #f1f1f1;
  padding: 10px;
  text-align: center;
  width: auto;
`;

const AuthorizeButton = styled.div`
  cursor: pointer;
  background: #22313a;
  color: white;
  letter-spacing: 1px;
  font-size: 12px;
  width: 100px;
  margin-left: 10px;
  display: block;
  margin: auto;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
`;

const Table: React.FC<TableProps> = ({ applications }) => {
  const [authorize] = useAuthorizeMutation();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: useMemo(
        () => [
          { Header: "id", accessor: "id" },
          { Header: "Email", accessor: "email" },
          { Header: "Authorized", accessor: "authorized" },
          { Header: "Date", accessor: "createdAt" },
          {
            Header: "Access",
            Cell: (props: any) => (
              <AuthorizeButton
                onClick={async () => {
                  const email =
                    applications.applications[parseInt(props.row.id)].email;
                  await authorize({
                    variables: { email },
                    refetchQueries: [{ query: ApplicationsDocument }],
                  });
                }}>
                Authorize
              </AuthorizeButton>
            ),
          },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [authorize]
      ),
      data: applications.applications.map((e) => ({
        ...e,
        authorized: e.authorized ? "Yes" : "No",
        createdAt: new Date(parseInt(e.createdAt)).toISOString().split("T")[0],
      })),
    });

  return (
    <Container>
      {rows.length > 0 && (
        <TableContainer {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableHead {...column.getHeaderProps()} style={{}}>
                    {column.render("Header")}
                  </TableHead>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell", { cell: cell.value })}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </tbody>
        </TableContainer>
      )}
    </Container>
  );
};

export default Table;
