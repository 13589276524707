import React, { useState } from "react";
import styled from "styled-components";
import SpeechRecognition from "./ASR/SpeechRecognition";
import TextToSpeechAugmentation from "./ASR/TextToSpeechAugmentation";

type ASRProps = {};

const Container = styled.div`
  position: relative;
`;
const ChangeButton = styled.div`
  position: absolute;
  top: 80%;
  left: 1%;
  width: 170px;
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #22313a;
  cursor: pointer;
  user-select: none;
`;

const ASR: React.FC<ASRProps> = () => {
  const [screenState, setScreenState] = useState("Speech Recognition");
  return (
    <Container>
      {screenState === "Speech Recognition" && <SpeechRecognition />}
      {screenState === "Text To Speech" && <TextToSpeechAugmentation />}

      <ChangeButton
        onClick={() => {
          if (screenState === "Speech Recognition") {
            setScreenState("Text To Speech");
          } else {
            setScreenState("Speech Recognition");
          }
        }}>
        {" "}
        {screenState}
      </ChangeButton>
    </Container>
  );
};

export default ASR;
